import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Media, } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Linkbutton from '../Components/LinkButton';
import { UpdateSubLocation, CountMtUser, CountVehicle, CountLoad, CountLiveVehicle, CountBusiness, CountUser, getSystemList, getSystemListSubLocality } from '../../services/api/api-service';
//i18n
import { withNamespaces } from 'react-i18next';
import _ from "lodash";
const Dashboard = (props) => {
    const [mtUserCount, setMtUserCount] = useState(0);
    const [mtGpsCount, setMtGpsCount] = useState(0);
    const [vehicleCount, setVehicleCount] = useState(0);
    const [availableLoadCount, setAvailableLoadCount] = useState(0);
    const [needTruckCount, setNeedTruckCount] = useState(0);
    const [packersAndMoversCount, setPackersAndMoversCount] = useState(0);
    const [transporterCount, setTransporterCount] = useState(0);
    const [unverifyUserCount, setUnverifyUserCount] = useState(0);
    const reports = [
        { title: "Moverstrip User", iconClass: "bxs-user", value: mtUserCount },
        { title: "MTGPS User", iconClass: "bxs-user", value: mtGpsCount },
        { title: "Total Vehicle", iconClass: "bxs-truck", value: vehicleCount },
        { title: "Available Load", iconClass: "bx-box", value: availableLoadCount },
        { title: "Live Vehicle", iconClass: "bxs-truck", value: needTruckCount },
        { title: "Packers And Movers", iconClass: "bxs-box", value: packersAndMoversCount },
        { title: "Transporter", iconClass: "bxs-truck", value: transporterCount },
        { title: "Unverify User", iconClass: "bxs-truck", value: unverifyUserCount },
    ];
    // const Linkbuttonf = [
    //     { title: "Live Vehicle Tracking", linkurl: "liveVehicle", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/live-truck.png" },
    //     { title: "Register Truck", linkurl: "vehicleRegister", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/reg-truck.png" },
    //     { title: "Vehicle Near You", linkurl: "nearVehicle", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/near-me.png" },
    //     { title: "Search Loads", linkurl: "postloadlList", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/load-post.png" },
    //     { title: "Rate Card", linkurl: "ratecard", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/ratecard.png" },
    //     { title: "Subscription", linkurl: "pricing", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/subcriber.png" },
    //     { title: "Transporter", linkurl: "transporterList", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/transporter.png" },
    //     { title: "Packers & Movers", linkurl: "packersAndMovers", iconImage: "https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/panelIcon/packers-and-movers.png" },
    // ];
    useEffect(() => {
        CountMtUser("MTGPS").then(data => {
            if (!_.isEmpty(data)) {
                setMtGpsCount(data.count);
            }
        })
        CountMtUser("MTSUBSCRIBE").then(data => {
            if (!_.isEmpty(data)) {
                setMtUserCount(data.count);
            }
        })
        CountVehicle().then(data => {
            if (!_.isEmpty(data)) {
                setVehicleCount(data.count);
            }
        })
        CountLoad().then(data => {
            if (!_.isEmpty(data)) {
                setAvailableLoadCount(data.count);
            }
        })
        CountLiveVehicle().then(data => {
            if (!_.isEmpty(data)) {
                setNeedTruckCount(data.count);
            }
        })
        CountBusiness("Packers And Movers").then(data => {
            if (!_.isEmpty(data)) {
                setPackersAndMoversCount(data.count);
            }
        })
        CountBusiness("Transporter").then(data => {
            if (!_.isEmpty(data)) {
                setTransporterCount(data.count);
            }
        })
        CountUser("I").then(data => {
            if (!_.isEmpty(data)) {
                setUnverifyUserCount(data.count);
            }
        })
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')} />
                    <Row>
                        {
                            reports.map((report, key) =>
                                <Col md="3" sm="3" key={"_col_" + key}>
                                    <Card className="mini-stats-wid" style={{ borderRadius: "0" }}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">{report.title}</p>
                                                    <h4 className="mb-0">{report.value}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle align-self-center" >
                                                    <span className="avatar-title" style={{backgroundColor:"#ff6600"}}>
                                                        <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
}

export default withNamespaces()(Dashboard);
