import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody} from "reactstrap";

class LinkButton extends Component {

    render() {
        const Linkbuttonf = this.props.Linkbuttonf;
        return (
            <React.Fragment>
                <Link to={Linkbuttonf.linkurl} >
                    <Card className="text-center" style={{borderRadius:"0"}}>
                        <CardBody>
                                    <div className="mb-4">
                                        <div className="rounded-circle avatar-sm mx-auto mb-4" style={{border:"2px solid #000",paddingTop:"10px",width:'4rem',height:'4rem',margin:'0 auto'}}>
                                        <img className="avatar-sm" src={Linkbuttonf.iconImage}  alt="" style={{width:'2.5rem',height:'2.5rem'}}  />
                                        </div>
                                    </div>
                                     <h5 className="font-size-15 text-dark">{Linkbuttonf.title}</h5>
                        </CardBody>
                      </Card>
                      </Link>
            </React.Fragment>
        );
    }
}

export default LinkButton;