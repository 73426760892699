import React from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import globelData from '../../../services/global-data'
import _ from 'lodash'
const BusinessProfileModal = (props) => {
    return (
        <Modal isOpen={props.modal} size="lg" role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={props.togglemodal}>
            <div className="modal-content">
                <ModalHeader toggle={props.togglemodal}>
                    User Details
                        </ModalHeader >
                <ModalBody>
                    <div className="table-responsive">
                        {props.userDetails &&
                            <>
                                <img src={_.get(props.userDetails, "businessCard") ? props.userDetails.businessCard !== "" ? props.userDetails.businessCard : globelData.defaultCard : globelData.defaultCard} style={{ width: "100%", height: 200 }} alt="Visiting Card" className={_.get(props.userDetails, "businessCard") ? props.userDetails.businessCard !== "" : "invisible"} />
                                <Table className="table table-sm m-0 table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Company Name:</th>
                                            <td>{props.userDetails.companyName}</td>
                                            <th scope="row">business Type:</th>
                                            <td colSpan="3">{props.userDetails.businessTypeTag}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Owner Name:</th>
                                            <td>{props.userDetails.ownerName}</td>
                                            <th scope="row">Owner Phone:</th>
                                            <td>{props.userDetails.ownerPhone}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Contact Person:</th>
                                            <td>{props.userDetails.contactPerson}</td>
                                            <th scope="row">Contact Number:</th>
                                            <td>{props.userDetails.contactNumber}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Website:</th>
                                            <td>{props.userDetails.website}</td>
                                            <th scope="row">Business Email:</th>
                                            <td>{props.userDetails.businessEmail}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vecile Available For:</th>
                                            <td>{props.userDetails.routeVehicleLoaded}</td>
                                            <th scope="row">Special Services:</th>
                                            <td>{props.userDetails.specialServices}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Transporter Type:</th>
                                            <td>{props.userDetails.vehicleCategory}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Address:</th>
                                            <td>{props.userDetails.address}</td>
                                            <th scope="row">pin:</th>
                                            <td>{props.userDetails.pin}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">City:</th>
                                            <td>{props.userDetails.city}</td>
                                            <th scope="row">State:</th>
                                            <td>{props.userDetails.state}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" onClick={props.togglemodal}>Close</Button>
                </ModalFooter>
            </div>
        </Modal>

    );

}

export default BusinessProfileModal;