import React, {useEffect, useState } from 'react';
import { getPostLoad,getSystemList,deletePostLoad } from '../../services/api/api-service';
import _ from 'lodash'
import SweetAlert from "react-bootstrap-sweetalert";
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { subscribe } from 'toastr';
const LoadCard = (props) => {
    console.log("data:-"+props.projects)
    const [postDetails, setPostDetails] = useState([]);
    const [modal, setModal] = useState(false);
    const [postValue,setPostValue]=useState(null);
    const [successMsg,setSuccessMsg]=useState(false);
    const alertMessage=(postId)=>{
        setPostValue(postId);
        setSuccessMsg(true);
    }
    const handlerDelete=()=>{
        deletePostLoad(postValue).then(r => {
            window.location.reload()
        })
    }
    const togglemodal = () => {
        setModal(false)
    }
    const activatePost = (postId) => {
        console.log("Post Load Id:-"+postId);
         getSystemList('city').then(citylist => {
            getPostLoad(postId).then(data => {
              if (!_.isEmpty(data)) {
                data.fromCityName = _.get(_.find(citylist, { value: data.pickupLocation }), "label", data.pickupLocation)
                data.destinationCityName = _.get(_.find(citylist, { value: data.destination }), "label", data.destination)
                setPostDetails(data)
                console.log("hello"+postDetails)
                // console.log("post load List:"+data)
                 setModal(true)
              }
            })
          }).catch(e => {
            console.log("Error in State list:", e);
          })
            .catch(e => {
              console.log("Error in Post Load list:", e);
            })
    }
    return (
        <>
        <React.Fragment>
            {
                props.projects.map((project, key) =>
                    <tr key={"_project_" + key} >
                        <td>
                            {project.vehicleType}
                        </td>
                        <td>
                            {project.fromCityName}
                        </td>
                        <td>
                            {project.destinationCityName}
                        </td>
                        <td>{_.get(project,"createdAt")?project.createdAt.slice(0, 10):""}</td>
                        <td>{project.goodsType}</td>
                        <td>{project.contactNumber}</td>
                        <td> 
                             <Button
                            color="primary"
                            onClick={() => activatePost(project.id)}
                            id="edittooltip2"
                            className="mr-3 text-success"
                        >
                            <i className="mdi mdi-eye font-size-18" style={{ color: 'white', }} ></i>
                        </Button>
                             <Button
                            color="danger"
                            onClick={() => alertMessage(project.id)}
                            id="edittooltip2"
                            className="text-danger"
                        >
                            <i className="mdi mdi-delete font-size-18" style={{ color: 'white', }} ></i>
                        </Button></td>
                    </tr>
                )
            }
           
        </React.Fragment>
        {successMsg ? (
											<SweetAlert
												title="Do You Delete"
												success
												showCancel
												confirmBtnBsStyle="success"
												cancelBtnBsStyle="danger"
												onConfirm={handlerDelete}
												onCancel={() => setSuccessMsg(false)}
											>
												You clicked the button!
											</SweetAlert>
										) : null}
        <Modal isOpen={modal} size="lg" role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={togglemodal}>
                <div className="modal-content">
                    <ModalHeader toggle={togglemodal}>
                        User Details
                            </ModalHeader >
                    <ModalBody>
                        <div className="table-responsive">
                            {
                                <Table className="table table-sm m-0 table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Vehicle Type:</th>
                                            <td>{postDetails.vehicleType}</td>
                                            <th scope="row">Goods Type:</th>
                                            <td colSpan="3">{postDetails.goodsType}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Loads Type:</th>
                                            <td>{postDetails.loadsType}</td>
                                            <th scope="row">Item Weight:</th>
                                            <td colSpan="3">{postDetails.itemWeight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Contact Name:</th>
                                            <td>{postDetails.contactName}</td>
                                            <th scope="row">Contact Number:</th>
                                            <td colSpan="3">{postDetails.contactNumber}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Pickup Location:</th>
                                            <td>{postDetails.fromCityName}</td>
                                            <th scope="row">Contact Number:</th>
                                            <td colSpan="3">{postDetails.destinationCityName}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">special Instruction:</th>
                                            <td colSpan="3">{postDetails.specialInstruction}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={togglemodal}>Close</Button>
                    </ModalFooter>
                </div>
            </Modal>
            </> 
    );
}
export default LoadCard;