import React, { Component } from "react";
import { Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Components
import Notifications from "../../components/Notification";
class notificationList extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Notification" breadcrumbItem="Dashboard" />
                        <Row>
                            {/* notifications */}
                            <Notifications/>
                        </Row>  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default notificationList;
