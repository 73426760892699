import React, { Component } from "react";
import { Row, Col,Container } from "reactstrap";
import KycForm from "../Components/KycForm"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class Kyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FormType: [
            {
                title: "Member KYC",Type: "New",
            },
        ]
    }
}
  render() {
    return (
      <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                    <Breadcrumbs title="Subscription" breadcrumbItem="Member KYC" />
                        <Row>
                            <Col lg="12">
                               <KycForm FormType={this.state.FormType}/>
                            </Col>
                        </Row>

                    </Container>
                </div>
      </React.Fragment>
    );
  }
}
export default Kyc;
