import React, { useEffect, useState } from 'react';
import {Row, Col, FormGroup, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getSystemList, getSystemCity, UpdateBusinessProfile, getBusinessProfile } from '../../services/api/api-service';
import Select from "react-select";
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import MtSweetAlert from '../Components/MtSweetAlert';
import MtFormLayout from '../Components/MtFormLayout';
import UploadFile from '../Components/uploadFile';
const UpdateBusinessList = (props) => {
    let history = useHistory();
    const image = 'https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/default-image.jpg';
    //alert 
    const [conformMsg, setConformMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Something Wrong !");
    //end-alert
    //
    const [transporterType, setTransporterType] = useState(null);
    const [transporterTypeList, setTransporterTypeList] = useState([]);
//
    const [listingCategory, setlistingCategory] = useState(null);
    const [listingCategoryList, setListingCategoryList] = useState([]);
    const [selectedListing, setSelectedListing] = useState([]);
    const [businessDetails, getBusinessDetails] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    const [stateList, setStatelist] = useState([]);
    const [selectedStateList, setSelectedStatelist] = useState(null);
    const [cityValue, setCityValue] = useState(null);
    const [cityList, setCitylist] = useState([]);
    const [selectedCityList, setSelectedCitylist] = useState(null);
    const [userMtId, setUserMtId] = useState(null);
    const [uploadData, setUploadData] = useState([]);
    ///some Error
    const [submitStatus, setSubmitStatus] = useState(false);
    //Upload file Veriable
    const [businessCardValue, setBusinessCardValue] = useState(null);
    const [serviceType, setServiceType] = useState(null);
    const [serviceTypeList, setServiceTypeList] = useState([]);
    const query = new URLSearchParams(props.location.search);
    const token = query.get('businessId')
    //console.log("Business Listing:-"+token)//123
    const goBack = () => {
        history.goBack()
        setSubmitStatus(false);
    }
    const handleValidSubmit = (event, values, actions) => {
        const mtVaue = {
            id: token,
            mtUserId: userMtId
        }
        const valueset = {
            state: _.get(stateValue, "value") ? stateValue.value : selectedStateList,
            city: _.get(cityValue, "value") ? cityValue.value : selectedCityList
        };
        const mtBusinessType = [];
        if (listingCategory == null) {
            mtBusinessType.push(selectedListing[0])
        } else {
            if (listingCategory.value === "Packers And Movers,Transporter") {
                mtBusinessType[0] = "Packers And Movers";
                mtBusinessType[1] = "Transporter";
            }
            else {
                mtBusinessType[0] = `${listingCategory.value}`;
            }
        }
        const dataValue = [];
        dataValue.push({
            ...valueset,
            ...mtVaue,
            companyName: values.companyName,
            contactPerson: values.contactPerson,
            address: values.address,
            businessEmail: values.businessEmail,
            website: values.website,
            businessTypeTag: mtBusinessType,
            pin: values.pin,
            ownerName: values.contactPerson,
            ownerPhone:values.contactNumber,
            contactNumber: values.contactNumber,
            businessCard: businessCardValue,
            routeVehicleLoaded: values.routeVehicleLoaded,
            specialServices: _.get(serviceType,"value")?serviceType.value:_.get(businessDetails, "specialServices") ? businessDetails.specialServices : "Not add any service",
            vehicleCategory: _.get(transporterType,"value")?transporterType.value:_.get(businessDetails, "vehicleCategory") ? businessDetails.vehicleCategory : "Not Applicable"
        });
        setUploadData(dataValue);
        console.log(JSON.stringify(dataValue));
        if (!_.isEmpty(dataValue)) {
            setConformMsg(true)
        } else {
            setSubmitStatus(true)
            setAlertMsg("Somthing Wrong !");
        }
    }
    // Submit Data Server
    const submitHandler = () => {
        console.log(uploadData);
        UpdateBusinessProfile(uploadData).then(result => {
            setConformMsg(false)
            setSuccessMsg(true);
        }).catch(alert);
    }
    useEffect(() => {
        getSystemList('listtype').then(setListingCategoryList);
        getSystemList('ServiceType').then(setServiceTypeList);
        getSystemList('TransporterType').then(setTransporterTypeList);
        const statList = [];
        getSystemList('state').then(data => {
            if (!_.isEmpty(data)) {
                data.forEach(element => {
                    statList.push({ label: element.label, value: element.value })
                });
                setStatelist(statList)
            }
        }).catch(e => {
            console.log("Error in State list:", e);
        })
    }, [])
    const getbusiness = (value) => {
        getBusinessProfile(value).then(data => {
            if (!_.isEmpty(data)) {
                getBusinessDetails(data)
                setSelectedCitylist(_.get(data, "city") ? data.city : null)
                setSelectedStatelist(_.get(data, "state") ? data.state : null)
                setSelectedListing(_.get(data, "businessTypeTag") ? data.businessTypeTag : null)
                setBusinessCardValue(_.get(data, "businessCard") ? data.businessCard ? data.businessCard : null : null)
                setUserMtId(_.get(data, "mtUserId") ? data.mtUserId : null)
                setTransporterType(_.get(data, "TransporterType") ? data.TransporterType : null)
            }
        })
    }
    useEffect(() => {
        getbusiness(token);
        //console.log(stateValue.value)
        if (stateValue) {
            console.log(stateValue.value)
            const cityList = [];
            getSystemCity(stateValue.value).then(data => {
                if (!_.isEmpty(data)) {
                    //console.log(data)
                    data.forEach(element => {
                        cityList.push({ label: element.dbCity, value: element.dbCity })
                    });
                    setCitylist(cityList)
                }
            }).catch(e => {
                console.log("Error in State list:", e);
            })
        }
    }, [stateValue])
    return (
        <React.Fragment>
            <MtFormLayout
                breadcrumbsTitle="Busines List"
                breadcrumbItem="Update Business Profile"
                title="Update Business Profile"
                subTitle="Fill The Details And Update Business Profile">
                <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} >
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Business Category</Label>
                                <Select
                                    value={listingCategory}
                                    onChange={setlistingCategory}
                                    options={listingCategoryList}
                                    classNamePrefix="select2-selection"
                                /> <p class="text-muted">{_.get(businessDetails, "businessTypeTag") ? businessDetails.businessTypeTag : null}</p>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Company Name</Label>
                                <AvField
                                    name="companyName"
                                    placeholder="Company Name"
                                    type="text"
                                    value={_.get(businessDetails, "companyName") ? businessDetails.companyName : "NULL"}
                                    errorMessage="Enter Company Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />


                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Contact Number</Label>
                                <AvField
                                    name="contactNumber"
                                    placeholder="Contact Number"
                                    type="text"
                                    value={_.get(businessDetails, "contactNumber") ? businessDetails.contactNumber : "NULL"}
                                    errorMessage="Enter Contact Number"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Contact Person</Label>
                                <AvField
                                    name="contactPerson"
                                    placeholder="Contact Person"
                                    type="text"
                                    value={_.get(businessDetails, "contactPerson") ? businessDetails.contactPerson : "NULL"}
                                    errorMessage="Enter Contact Person"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Mail Id</Label>
                                <AvField
                                    name="businessEmail"
                                    placeholder="Business Email"
                                    type="text"
                                    value={_.get(businessDetails, "businessEmail") ? businessDetails.businessEmail : "example@gmail.com"}
                                    errorMessage="Enter Business Email"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Website Url</Label>
                                <AvField
                                    name="website"
                                    placeholder="website"
                                    type="text"
                                    value={_.get(businessDetails, "website") ? businessDetails.website : "NULL"}
                                    className="form-control"
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Address</Label>
                                <AvField
                                    name="address"
                                    placeholder="Address"
                                    type="text"
                                    value={_.get(businessDetails, "address") ? businessDetails.address : "NULL"}
                                    errorMessage="Enter Address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">State</Label>
                                <Select
                                    value={stateValue}
                                    onChange={setStateValue}
                                    options={stateList}
                                    name="state"
                                />
                                <p class="text-muted">{selectedStateList}</p>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">City</Label>
                                <Select
                                    name="city"
                                    value={cityValue}
                                    onChange={setCityValue}
                                    options={cityList}
                                />
                                <p class="text-muted">{selectedCityList}</p>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Pin Code</Label>
                                <AvField
                                    name="pin"
                                    placeholder="Pin Code"
                                    type="text"
                                    value={_.get(businessDetails, "pin") ? businessDetails.pin : "NULL"}
                                    errorMessage="Enter Pin Code"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Route Vehicle Loaded</Label>
                                <AvField
                                    name="routeVehicleLoaded"
                                    placeholder="Route Vehicle Loaded"
                                    type="text"
                                    value={_.get(businessDetails, "routeVehicleLoaded") ? businessDetails.routeVehicleLoaded : "NULL"}
                                    errorMessage="Enter Route Vehicle Loaded"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Special Services</Label>
                                <Select
                                    value={serviceType}
                                    onChange={setServiceType}
                                    options={serviceTypeList}
                                    classNamePrefix="select2-selection"
                                />
                               <p class="text-muted">{_.get(businessDetails, "specialServices") ? businessDetails.specialServices : "Not add any service"}</p>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Transporter Type</Label>
                                <Select
                                    value={transporterType}
                                    onChange={setTransporterType}
                                    options={transporterTypeList}
                                    classNamePrefix="select2-selection"
                                />
                                 <p class="text-muted">{_.get(businessDetails, "vehicleCategory") ? businessDetails.vehicleCategory : "Not Applicable"}</p>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Business Card(Current Image)</Label>
                                <img src={_.get(businessDetails, "businessCard") ? businessDetails.businessCard ? businessDetails.businessCard : image : image} style={{ display: 'block' }} height={150} width={200} alt={_.get(businessDetails, "companyName") ? businessDetails.companyName : "MtVisitingCard"} />
                            </FormGroup>
                        </Col>
                      
                        <Col md="12">
                            <FormGroup>
                                <UploadFile labelValue="Upload Business Card"
                                    bucketName="moverstrip-prod"
                                    fileValue={setBusinessCardValue}
                                    UploadPath="business-cards/"
                                    currentImage={null} />
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            {submitStatus &&
                                <div className="alert alert-danger fade show" role="alert">{alertMsg}</div>
                            }
                        </Col>
                    </Row>
                    <Button color="primary" type="submit">Submit form</Button>
                </AvForm>
            </MtFormLayout>
            {conformMsg ? (
                <MtSweetAlert
                    alertTitle="Update Business Profile"
                    alertConfirmBtnText="Submit"
                    alertHandler={submitHandler}>
                    Do You Submit Data
                </MtSweetAlert>
            ) : null}
            {successMsg ? (
                <MtSweetAlert
                    alertTitle="Business Profile"
                    alertConfirmBtnText="Go Back"
                    alertHandler={goBack} >
                    Business Profile Update Successfully
                </MtSweetAlert>
            ) : null}
        </React.Fragment>
    );
}
export default UpdateBusinessList