import React, { Component } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button, CardTitle,Container} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {removeLocalData} from '../../services/global-storage';
import {changeUserPassword} from '../../services/api/api-service'
class ChangePassword extends Component {
  handleValidSubmit = (event, values) => {
    //const { signOut } = React.useContext(AuthContext);
    changeUserPassword({ oldPassword: values.currentpassword, newPassword: values.password }).then(result => {
      removeLocalData('accessToken')
      console.log(result);
    })
      .catch(alert)
    console.log(values)
}
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Change Password" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Change Password</CardTitle>
                    <AvForm onValidSubmit={this.handleValidSubmit} >
                    <AvField
                        name="currentpassword"
                        type="password"
                        label="Current Password"
                        placeholder="Password"
                        errorMessage="Enter current password"
                        validate={{ required: { value: true } }}
                      />
                      <AvField
                        name="password"
                        type="password"
                        label="Enter New Password"
                        placeholder="Password"
                        errorMessage="Enter password"
                        validate={{ required: { value: true } }}
                      />
                      <AvField
                        name="confirmpassword"
                        type="text"
                        label="Confirm Password"
                        placeholder="Re-type Password"
                        errorMessage="Enter Re-password"
                        validate={{
                          required: { value: true },
                          match: { value: "password" }
                        }}
                      />
                      <FormGroup className="mb-0">
                        <div>
                          <Button type="submit" color="primary" className="mr-1">
                            Submit
                        </Button>{" "}
                          <Button type="reset" color="secondary">
                            Cancel
                        </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              
            </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
