import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Container, Label } from "reactstrap";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from 'react-router-dom';
import SelectList from "../Components/SelectList";
//Import Breadcrumb
import _ from 'lodash'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { UpdateUser, getUser } from '../../services/api/api-service'
const UpdateDetails = (props) => {
  let history = useHistory();
  const [stateValue, setStateValue] = useState(null);
  const [listingValue, setListingValue] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [updateProfile, setUpdateProfile] = useState({});
  const query = new URLSearchParams(props.location.search);
  const mtUserId = query.get('mtUserId')
  const goBack = () => {
    //alert(JSON.stringify(updateProfile));
    if (!_.isEmpty(updateProfile)) {
      UpdateUser(mtUserId, updateProfile).then(data => {
        history.goBack()
        setsuccess_msg(false);
      })
    }

  }
  const handleValidSubmit = (event, values, actions) => {
    const dropdownList = {
      state: _.get(stateValue, "label") ? stateValue.label : updateProfile.state,
      businessTypeTag: _.get(listingValue, "value") ? listingValue.value : updateProfile.businessTypeTag,
    }
    const datalist = [];
    datalist.push({
      ...values, ...dropdownList
    })
    //console.log(datalist);
    setUpdateProfile(datalist);
    setsuccess_msg(true);
  }
  useEffect(() => {
    getUser(mtUserId).then(data => {
      setUpdateProfile(data[0]);
    })
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Update User Details" breadcrumbItem="Dashboard" />
          <AvForm onValidSubmit={handleValidSubmit} >
            <Card>
              <CardBody>
                <CardTitle>Update User Details</CardTitle>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="productname">Company Name</Label>
                      <AvField
                        name="contactName"
                        placeholder="Contact Name"
                        type="text"
                        errorMessage="Enter Your Contact Name"
                        className="form-control"
                        value={updateProfile.contactName}
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="productname">Contact Number</Label>
                      <AvField
                        name="contactNumber"
                        placeholder="Contact Number"
                        type="text"
                        value={updateProfile.contactNumber}
                        errorMessage="Enter Your Contact Number"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="productname">Email</Label>
                      <AvField
                        name="email"
                        placeholder="Email"
                        type="text"
                        value={updateProfile.email}
                        errorMessage="Enter Your Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField type="select" value={updateProfile.userType} name="userType" label="User Type" helpMessage="Select User Type">
                        <option>Select User Type</option>
                        <option>MTGPS</option>
                        <option>MTSUBSCRIBE</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <SelectList label="State" name="state" type="state" setStateValue={setStateValue} stateValue={stateValue}></SelectList>
                      <Label>{updateProfile.state}</Label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <SelectList label="Category" name="businessTypeTag" type="listtype" setStateValue={setListingValue} stateValue={listingValue}></SelectList>
                      <Label>{updateProfile.businessTypeTag}</Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit">Submit</Button>
                {success_msg ? (
                  <SweetAlert
                    title="Do You Submit"
                    success
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={goBack}
                    onCancel={() => setsuccess_msg(false)}
                  >
                    You clicked the button!
                  </SweetAlert>
                ) : null}
              </CardBody>
            </Card>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default UpdateDetails;
