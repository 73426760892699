import { RFC_2822 } from 'moment';
import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Button, Label, Input } from "reactstrap";
import react, { useEffect, useState } from 'react';
import { AvForm, AvField } from "availity-reactstrap-validation";
import MtFormLayout from '../Components/MtFormLayout';
import { useHistory } from 'react-router-dom';
import MtSweetAlert from '../Components/MtSweetAlert';
import {createUser} from '../../services/api/api-service';
import { authenticationService } from '../../services/api/auth-service'
import _, { repeat } from 'lodash';
const SystemData = () => {
    function betweenRandum(min, max) {  
        return Math.floor(
          Math.random() * (max - min) + min
        )
    }
    
    let history = useHistory();
    const goBack = () => {
        history.goBack()
    }
    //alert 
    const [conformMsg, setConformMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Something Wrong !");
    const [errorStatus, setErrorStatus] = useState(false);
    const [defaultPassword]=useState(betweenRandum(100000,1000000).toString());
    //handleSubmit
    const [submitValue,setSubmitValue]=useState([]);
    const handleValidSubmit = (event, values, actions) => {
      const currentDate=new Date();
        const valueData=[]
        valueData.push({
            phoneVerified:true,
            contactName:values.contactName,
            userType:values.userType,
            username:values.contactNumber,
            password:defaultPassword,
            email:values.email,
            contactNumber:values.contactNumber,
            isTermsAgreed: false,
            status:"A",
            codeCreatedAt:currentDate
        })
        console.log(valueData);
        if(values.userType=="Select User Type")
        {
            setErrorStatus(true);
            setAlertMsg("User Type Not Selected !");
        }
        else
        {
            setSubmitValue(valueData[0]);
            setConformMsg(true);
        }
    }
    const submitHandler = () => {
        createUser(submitValue).then(data=>{
            if (!_.isEmpty(data)) {
             setSuccessMsg(true);
             console.log(data);
            }
        }).catch(e=>{
            console.log(e);
        })
    }
    return (
        <React.Fragment>
            <MtFormLayout
                breadcrumbsTitle="User"
                breadcrumbItem="Add User"
                title="Add Mt User"
                subTitle="Add New User Movers Account">
                <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} >
                    <Row>
                       
                        <Col md="6">
                            <FormGroup>
                                <AvField type="select" name="typeList" label="Select Type" helpMessage="Select Type List">
                                    <option>Select User Type</option>

                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Email</Label>
                                <AvField
                                    name="email"
                                    placeholder="Email"
                                    type="text"
                                    errorMessage="Enter Email"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            {errorStatus &&
                                <div className="alert alert-danger fade show" role="alert">{alertMsg}</div>
                            }
                        </Col>
                        <Col md="12">
                            <Button color="success" type="submit">Submit</Button>
                            {conformMsg ? (
                                <MtSweetAlert
                                    alertTitle="Add Business Profile"
                                    alertConfirmBtnText="Submit"
                                    alertHandler={submitHandler}>
                                    Do you submit data
                                </MtSweetAlert>
                            ) : null}
                            {successMsg ? (
                                <MtSweetAlert
                                    alertTitle="Business Profile"
                                    alertConfirmBtnText="Go Back"
                                    alertHandler={goBack} >
                                    Business profile add successfully Mtuser password is:-{defaultPassword}
                                    
                                </MtSweetAlert>
                            ) : null}
                        </Col>
                    </Row>
                </AvForm>
            </MtFormLayout>
        </React.Fragment>
    );
}
export default SystemData;