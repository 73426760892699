import React from "react";
import { Row, Col, Container } from "reactstrap";
import EditVehicle from "./vehicleComponents/editVehicle"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import _ from 'lodash';
const EditVehicleDetails = (props) => {
  const query = new URLSearchParams(props.location.search);
  const mtUserId = query.get('mtUseridValue');
  const vehicleIdValue = query.get('vehicleId');
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Vehicle" breadcrumbItem="Edit vehicle details" />
          <Row>
            <Col lg="12">
              <EditVehicle
                title="Edit vehicle details"
                Type="Edit"
                userId={mtUserId}
                vehicleDetailsId={vehicleIdValue} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EditVehicleDetails;
