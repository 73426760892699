import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, FormGroup, CardTitle, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Cards
import _ from 'lodash'
import Loadcard from "./LoadCard";
import VehicleType from "../Components/VehicleType";
import GoodsType from "../Components/GoodsType";
import LoadType from "../Components/LoadType";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getLoadPostList, findPostLoad, getSystemList,getLoadPostFilterList } from '../../services/api/api-service';
import Pagination from '../Components/pagination';
const PostLoadList = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [postLoadListData, setPostLoadListData] = useState([]);
    const [cityList,setCityList]=useState([]);
    const handleValidSubmit = (event, values) => {
        alert(values.searchText);
        findPostLoad(values.searchText).then(data => {
            if (!_.isEmpty(data)) {
                data.fromCityName = _.get(_.find(cityList, { value: data.pickupLocation }), "label", data.pickupLocation)
                data.destinationCityName = _.get(_.find(cityList, { value: data.destination }), "label", data.destination)
                setPostLoadListData(data);
            }
        })
    }
    const fetchPostLoadList = (citylist, location) => {
        getLoadPostList(location).then(result => {
            result.map(r => {
                r.fromCityName = _.get(_.find(citylist, { value: r.pickupLocation }), "label", r.pickupLocation)
                r.destinationCityName = _.get(_.find(citylist, { value: r.destination }), "label", r.destination)
                return r;
            })
            setPostLoadListData(result)
            console.log("--------result-------");
            console.log(result);
        })
    }
    const filterVehicleTypeHeadle=(event,value)=>{
        //alert(value)
        getFilter("vehicleType",value)
    }
    const filterGoodsTypeHeadle=(event,value)=>{
        //alert(value)
        getFilter("goodsType",value)
    }
    const filterLoadTypeHeadle=(event,value)=>{
        //alert(value)
        getFilter("loadsType",value)
    }
    const getFilter=(type,valueData)=>{
        getLoadPostFilterList(type,valueData).then(result => {
            result.map(r => {
                r.fromCityName = _.get(_.find(cityList, { value: r.pickupLocation }), "label", r.pickupLocation)
                r.destinationCityName = _.get(_.find(cityList, { value: r.destination }), "label", r.destination)
                return r;
            })
            setPostLoadListData(result)
        })
    }
    useEffect(() => {
        getSystemList('city').then(citylist => {
            if (!_.isEmpty(citylist)) {
                setCityList(citylist);
                fetchPostLoadList(citylist);
            }

        }).catch(e => {
            console.log("Error in State list:", e);
        })
    }, [])
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = postLoadListData.slice(indexOfFirstPost, indexOfLastPost);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Vehicle" breadcrumbItem="VEHICLE LOADS LIST" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Search By Filter</CardTitle>
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row>
                                            <Col md="2">
                                                <VehicleType handlerfilter={filterVehicleTypeHeadle} />
                                            </Col>
                                            <Col md="2">
                                                <GoodsType handlerfilter={filterGoodsTypeHeadle} />
                                            </Col>
                                            <Col md="2">
                                                <LoadType handlerfilter={filterLoadTypeHeadle} />
                                            </Col>
                                            <Col md="4">
                                                <AvField
                                                    name="searchText"
                                                    label="Search"
                                                    placeholder="Search..."
                                                    type="searchText"
                                                    helpMessage="Search By Number And Name"
                                                /></Col>
                                            <Col md="2">
                                                <FormGroup className="mb-0 mt-4 ">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1">
                                                            Search
                        </Button>

                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {currentPosts.length} entries</div>
                        </Col>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Vehicle Type</th>
                                                <th scope="col">Pickup Location</th>
                                                <th scope="col">destination</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Goods Type</th>
                                                <th scope="col">Contact</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Loadcard projects={currentPosts} />
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={postLoadListData.length}
                                paginate={paginate} />
                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
}
export default PostLoadList;