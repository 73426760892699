import React, { Component } from 'react';
import MapView from "../Components/MapView";
import { Row, Col, Card, CardBody,} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class VehicleLocaiton extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Live Vehicle" breadcrumbItem="Vehicle Live Location" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                   <MapView />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default VehicleLocaiton;