import React, { useState } from 'react';
import { getNeedLoad, getSystemList, deleteNeedLoad } from '../../services/api/api-service';
import _ from 'lodash'
import SweetAlert from "react-bootstrap-sweetalert";
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const NeedLoad = (props) => {
    const [needLoadDetails, setNeedLoadDetails] = useState([]);
    const [modal, setModal] = useState(false);
    const [needLoadValue, setNeedLoadValue] = useState(null);
    const [successMsg, setSuccessMsg] = useState(false);
    const alertMessage = (postId) => {
        setNeedLoadValue(postId);
        setSuccessMsg(true);
    }
    const handlerDelete = () => {
        deleteNeedLoad(needLoadValue).then(r => {
            window.location.reload();
        })
    }
    const togglemodal = () => {
        setModal(false)
    }
    const activatePost = (postId) => {
        getSystemList('city').then(citylist => {
            getNeedLoad(postId).then(data => {
                if (!_.isEmpty(data)) {
                    data.fromCityName = _.get(_.find(citylist, { value: data.fromLocation }), "label", data.fromLocation)
                    setNeedLoadDetails(data)
                    setModal(true)
                }
            })
        }).catch(e => {
            console.log("Error in State list:", e);
        })
            .catch(e => {
                console.log("Error in Post Load list:", e);
            })
    }
    return (
        <>
            <React.Fragment>
                {
                    props.projects.map((project, key) =>
                        <tr key={"_project_" + key} >
                            <td>
                                {project.vehicleNumber}
                            </td>
                            <td>
                                {project.itemWeight}
                            </td>
                            <td>
                                {project.fromCityName}
                            </td>
                            <td>
                                {project.toLocation}
                            </td>
                            <td>{_.get(project, "createdAt") ? project.createdAt.slice(0, 10) : ""}</td>
                            <td>{project.contactNumber}</td>
                            <td>
                                <Button
                                    color="primary"
                                    onClick={() => activatePost(project.id)}
                                    id="edittooltip2"
                                    className="mr-3 text-success"
                                >
                                    <i className="mdi mdi-eye font-size-18" style={{ color: 'white', }} ></i>
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={() => alertMessage(project.id)}
                                    id="edittooltip2"
                                    className="text-danger"
                                >
                                    <i className="mdi mdi-delete font-size-18" style={{ color: 'white', }} ></i>
                                </Button></td>
                        </tr>
                    )
                }

            </React.Fragment>
            {successMsg ? (
                <SweetAlert
                    title="Do You Delete"
                    success
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={handlerDelete}
                    onCancel={() => setSuccessMsg(false)}
                >
                    You clicked the button!
                </SweetAlert>
            ) : null}
            <Modal isOpen={modal} size="lg" role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={togglemodal}>
                <div className="modal-content">
                    <ModalHeader toggle={togglemodal}>
                        Need Load Details
                            </ModalHeader >
                    <ModalBody>
                        <div className="table-responsive">
                            {
                                <Table className="table table-sm m-0 table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Vehicle Number:</th>
                                            <td>{needLoadDetails.vehicleNumber}</td>
                                            <th scope="row">Item Weight:</th>
                                            <td colSpan="3">{needLoadDetails.itemWeight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">From Location:</th>
                                            <td>{needLoadDetails.fromCityName}</td>
                                            <th scope="row">Destination:</th>
                                            <td colSpan="3">{needLoadDetails.toLocation}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Contact Name:</th>
                                            <td>{needLoadDetails.contactName}</td>
                                            <th scope="row">Contact Number:</th>
                                            <td colSpan="3">{needLoadDetails.contactNumber}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={togglemodal}>Close</Button>
                    </ModalFooter>
                </div>
            </Modal>
        </>
    );
}
export default NeedLoad;