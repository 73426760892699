import React, { useEffect, useState } from 'react';
import {Row,Col} from 'reactstrap';
import _ from 'lodash';
import MtVehicleList from '../Components/mtVehicleList';
import {getVehicleList} from '../../services/api/api-service'
const UserVehicleList=(props)=>{
    const query = new URLSearchParams(props.location.search);
    const [mtUserId,setMtUserId]=useState(null);
    const [dataList,setDataList]=useState([]);
    useEffect(()=>{
        setMtUserId(query.get('mtUserId'));
        getVehicleList(query.get('mtUserId')).then(data => {
            if (!_.isEmpty(data)) {
                setDataList(data)
            }
        }).catch(e => {
            alert(e)
        })
    },[])
    return(
        <>
        <Row>
            <Col lg="12">
                <MtVehicleList vehicleList={dataList} />
            </Col>
        </Row>
        </>
    )
}
export default UserVehicleList;