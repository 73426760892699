const exportdefault = {
    userStatus:[
        {
            label:"---Select---",
            value:""
        },
        {
            label:"Verify",
            value:"A"
        },
        {
            label:"Unverify",
            value:"I"
        },
    ],
    defaultCard:"https://moverstrip-dev.s3.us-east-1.amazonaws.com/system/visitingCard.jpg"
};
export default exportdefault;