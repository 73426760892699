import React, { useEffect, useState } from 'react';
import { Container} from "reactstrap";
//Import Breadcrumb
import _ from 'lodash'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { userSubscriberList} from '../../services/api/api-service';
//Import Image
import List from './ListComponent/list';
const UnsubcriberUser = (props) => {
    const [businessList, setbusinessList] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    useEffect(() => {
        userSubscriberList('D').then(data => {
            if (!_.isEmpty(data)) {
                setbusinessList(data)
            }
        })
    }, [stateValue])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Listing" breadcrumbItem="Unsubscriber List" />
                    <List title={'Unsubscriber User list'} listData={businessList} userlists={setbusinessList} userFilterType="MTSUBSCRIBE"/>        
                </Container>
            </div>
        </React.Fragment>
    );
}
export default UnsubcriberUser;