import { create, find, upsertPatch, findOne,deleteById,count } from './core-service'
import { getLocalData} from '../global-storage'
import apiKit, { axiosRequest } from "./axios-base";
export const getSystemList = (type) => {
    return new Promise((resolve, reject) => {
        find('MtSystemLists', { "where": { "listType": type }, order: "label asc" })
            .then(data => {
                // storeCachedData(`${type}List`, data)
                resolve(data);
            })
    })
}
export const createBusinessListing = (data) => {
    return create('MtBusinessProfiles', data)
}
export const createLoadPost = (data) => {
    return create('MtVehicleLoads', data)
}
export const getLoadPostList = (location, mtUserId) => {
    const filter = { where: {}, order: "id desc" };
    if (location) {
        filter.where.or = [{ pickupLocation: location }, { destination: location }];
    }
    if (mtUserId) filter.where = [{ mtUserId : mtUserId }];
    return find('MtVehicleLoads', filter)
}
export const getUserGetLoadPostList = (mtUserId) => {
    return find('MtVehicleLoads', { "where": { "mtUserId": mtUserId}, order: "id desc" })
}
export const getBusinessList = (type, city) => {
    return find('MtBusinessProfiles', { "where": {"businessTypeTag": type, "city": city }, order: "companyName asc" })
}
export const findRegisterBusiness = (mobileNumber,cityName) => {
    const filter = { where: {}, order: "id desc" };
    if (mobileNumber) {
        filter.where.and= [{ contactNumber:mobileNumber },{ city:cityName }];
    }
    return find('MtBusinessProfiles', filter)
}
export const getBusinessListState = (type, state) => {
    const filter = { where: {},order: "id desc" };
    if (type) {
        filter.where.or = [{ businessTypeTag: type }, { state: state }];
    }
    return find('MtBusinessProfiles', filter)
}
export const getUserBusinessList=(type,value)=>{
    const filter = { where: {},include:["mtBusinessProfiles"],order: "id desc" };
    if (type) {
       filter.where.and=value?[{status:value},{userType:type}]:[{userType:type}];
    } return find('MtUsers',filter)
}

export const getRateCardList = (Type) => {
    return find('MtRateCards', { "where": { "vehicleType": Type } })
}
export const getPricingList = (status) => {
    return find('MtSubscriptionPlans', { "where": { "status": status } })
}
export const getVehicleList = (mtUserId) => {
    return find('MtVehicles', { "where": { "mtUserId": mtUserId } })
}
export const getVehicleDetailsList = (vehicleNo) => {
    return find('MtVehicles', { "where": { "vehicleNumber": vehicleNo } })
}
export const getBusinessProfileView = (mtUserid) => {
    return find('MtBusinessProfiles', { "where": { "mtUserId": mtUserid } })
}
export const getBusinessProfileViewListing = (id) => {
    return find('MtBusinessProfiles', { "where": { "id": id } })
}
export const UpdateLoadPost = (data) => {
    return upsertPatch('MtVehicleLoads', data)
}
export const CreateVehicleRegister = (data) => {
    return create('MtVehicles', data)
}
export const UpdateVehicleRegister = (data) => {
    return upsertPatch('MtVehicles', data)
}
export const CreateKycRegister = (data) => {
    return create('MtKYCs', data)
}
export const UpdateKycRegister = (data) => {
    return upsertPatch('MtKYCs', data)
}
export const getVehicleLocations = (filter, vehicleNumber) => {
    return axiosRequest(
        'GET',
        `${["MtVehicles", 'findAllDevices'].join('/')}`,
        undefined,
        undefined,
        { filter, vehicleNumber },
        true
    )
}
export const getVehicleLocationStats = () => {
    return axiosRequest(
        'GET',
        ["MtVehicles", 'findAllDeviceStats'].join('/')
    )
}
export const changeUserPassword = (payload) => {
    return axiosRequest(
        'POST',
        `${["MtUsers", 'change-password'].join('/')}`,
        undefined,
        payload,
        undefined,
        true
    )
}
export const getVehicleActiveList = () => {
    return axiosRequest(
        'GET',
        ["MtVehicles", 'allActiveVehicleList'].join('/')
    )
}
export const getHourlyStats = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'hourlyStats'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getHourlyDetail = (vehicleNumber, dateRange) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'hourlyDetail'].join('/'),
        undefined,
        undefined,
        { vehicleNumber, dateRange },
        true
    )
}
export const getAvgSpeedStats = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'avgSpeedStats'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getAvgSpeedDetail = (vehicleNumber, dateRange) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'avgSpeedDetail'].join('/'),
        undefined,
        undefined,
        { vehicleNumber, dateRange },
        true
    )
}
export const getStoppageStats = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'stoppageStats'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getStoppageDetail = (vehicleNumber, dateRange) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'stoppageDetail'].join('/'),
        undefined,
        undefined,
        { vehicleNumber, dateRange },
        true
    )
}
export const getMovementStats = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'movementStats'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getMovementDetail = (vehicleNumber, dateRange) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'movementDetail'].join('/'),
        undefined,
        undefined,
        { vehicleNumber, dateRange },
        true
    )
}
export const getNotificationList = (status) => {
    return find('MtNotifications', { "where": { "status": status } })
}
export const getVehicleSummary = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'vehicleSummary'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getTravelStats = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'travelStats'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const getIgnitionAlert = (vehicleNumber) => {
    return axiosRequest(
        'GET',
        ["MtDeviceData", 'ignitionAlert'].join('/'),
        undefined,
        undefined,
        { vehicleNumber },
        true
    )
}
export const sendOtp = (handle, mode) => {
    return axiosRequest(
        'POST',
        ["MtUsers", 'sendOTP'].join('/'),
        undefined,
        undefined,
        { handle, mode },
        true
    )
}
export const verifyOtp = (phone, otp) => {
    return axiosRequest(
        'POST',
        ["MtUsers", 'verifyOTP'].join('/'),
        undefined,
        undefined,
        { phone, otp },
        true
    )
}
export const resetUserPassword = (handle, otp, newPassword) => {
    return axiosRequest(
        'POST',
        ["MtUsers", 'resetUserPassword'].join('/'),
        undefined,
        undefined,
        { handle, otp, newPassword },
        true
    )
}
export const getNearbyVehicle = (vehicleNumber, distance) => {
    return axiosRequest(
        'GET',
        ["MtVehicles", 'nearbyVehicle'].join('/'),
        undefined,
        undefined,
        { vehicleNumber, distance },
        true
    )
}
export const getFaq = (type) => {
    return find('MtFaqs', { "where": { "faqType": type } })
}
export const getUniqueLocations = (businessType, state) => {
    return axiosRequest(
        'GET',
        ["MtBusinessProfiles", 'getUniqueLocations'].join('/'),
        undefined,
        undefined,
        { businessType, state },
        true
    )
}
export const getUser = (userid) => {
    return find('MtUsers', { "where": { "id": userid } })
}

export const createNeedLoad = (data) => {
    return create('MtNeedLoads', data)
}
export const getSystemListType = (type) => {
    return find('MtSystemLists', { "where": { "listType": type }, order: "orderValue asc" })
}
export const getLoadPostListFilter = (location) => {
    return find('MtVehicleLoads', { "where": { "pickupLocation": location }, order: "id asc" })
}
export const getPostLoad = (postId) => {
    return findOne('MtVehicleLoads', { "where": { "id": postId } })
}
export const getNeedLoadList = (Location) => {
    return Location === "All" ? find('MtNeedLoads', { order: "id desc" }) : find('MtNeedLoads', { "where": { "fromLocation": Location }, order: "id desc" })
}
export const getSystemListCityLabel = (dataValue) => {
    return find('MtSystemLists', { "where": { "value": dataValue } })
}
export const getSystemListCity = (state) => {
    return find('MtSystemLists', { "where": { "state": state }, order: "id asc" })
}
export const createHelpDesks = (data) => {
    return create('MtHelpDesks', data)
}
export const getAllUser = (type) => {
        return find('MtUsers',{ "where": {"userType":type}, order: "id desc" })
}
export const getHelpDesks = () => {
    return find('MtHelpDesks',{ where: {}, order: "id desc" })
}
export const findUser = (type,filterValue) => {
    const filter = { where: {},include:["mtBusinessProfiles"],order: "id desc" };
    const value=filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
    if (filterValue) {
        filter.where.or = [{ contactName:{like:value}},{ username:{like:value}},{contactNumber:{like:value}}];
        filter.where.and=[{userType:type}];
    }
    return find('MtUsers', filter)
}
export const UpdateUser = (id,data) => {
    return upsertPatch(`MtUsers/${id}`, data)
}
export const findBusiness = (filterValue,type) => {
    const filter = { where: {}, order: "id desc" };
    const value=filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
    if (filterValue) {
       filter.where.or = [{ ownerPhone:{like:value}},{contactNumber:{like:value}},{ownerName:{like:value}},{ state: {like:value} },{ city:{like:value}}];
       filter.where.and=[{businessTypeTag:type}];
    }
    return find('MtBusinessProfiles', filter)
}
export const findPostLoad = (filterValue,type) => {
    const filter = { where: {}, order: "id desc" };
    const value=filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
    if (filterValue) {
       filter.where.or = [{ contactName:{like:value}},{ contactNumber:{like:value}}];
    }
    return find('MtVehicleLoads', filter)
}
export const getLoadPostFilterList=(type,value)=>{
    const filter={where:{},order:"id desc"};
    if(type==="vehicleType"){
        filter.where.and=[{vehicleType:value}];
    }
    else if(type==="goodsType"){
        filter.where.and=[{goodsType:value}];
    }
    else if(type==="loadsType"){
        filter.where.and=[{loadsType:value}];
    }
    return find('MtVehicleLoads',filter)
}
export const deletePostLoad=(postId)=>{
    return deleteById('MtVehicleLoads',postId)
}
export const deleteNeedLoad=(postId)=>{
    return deleteById('MtNeedLoads',postId)
}
export const findNeedLoad = (filterValue) => {
    const filter = { where: {}, order: "id desc" };
    if (filterValue) {
       filter.where.or = [{ vehicleNumber: filterValue }, { toLocation: filterValue }];
    }
    return find('MtNeedLoads', filter)
}
export const getNeedLoad = (postId) => {
    return findOne('MtNeedLoads', { "where": { "id": postId } })
}
export const getSystemCity = (type) => {
    return find('MtSystemLists', { "where": { "state": type } })
}
export const getHelp = (helpid) => {
    return find('MtHelpDesks', { "where": { "id": helpid } })
}
export const getBusinessProfile = (id) => {
    return findOne('MtBusinessProfiles', { "where": { "id": id } })
}
export const UpdateBusinessProfile = (data) => {
    return upsertPatch('MtBusinessProfiles', data)
}
export const UpdateHelpDesk = (id,data) => {
    return upsertPatch(`MtHelpDesks/${id}`, data)
}
export const CountMtUser = (type) => {
    return count('MtUsers',null,{"userType":type})
}
export const CountVehicle = () => {
    return count('MtVehicles',{})
}
export const CountLoad = () => {
    return count('MtVehicleLoads',{})
}
export const CountLiveVehicle=()=>{
    return count('MtNeedLoads',{})
}
export const CountBusiness=(type)=>{
    return count('MtBusinessProfiles',null,{"businessTypeTag":type})
}
export const CountUser=(value)=>{
    return count('MtUsers',null,{"status":value})
}
export const deleteBusinessProfile=(businessId)=>{
    return deleteById('MtBusinessProfiles',businessId)
}
export const filterUser=(type,value)=>{
    const filter = { where: {},include:["mtBusinessProfiles"],order: "id desc" };
    if (type) {
       filter.where.and=value?[{status:"A"},{userType:type}]:[{status:"I"},{userType:type}];
    } return find('MtUsers',filter)
}
export const getUserBusinessProfile=(userid)=>{
    return find('MtUsers', { "where": { "id": userid },include:["mtBusinessProfiles"]})
}
export const resetPasswordPanel = (userId,debug) => {
    return axiosRequest(
        'POST',
        ["MtUsers", 'resetUserPassFromPanel'].join('/'),
        undefined,
        undefined,
        { userId,debug},
        true
    )
}
export const changeStatus = (userId,newStatus) => {
    return axiosRequest(
        'POST',
        ["MtUsers", 'changeUserStatus'].join('/'),
        undefined,
        undefined,
        { userId,newStatus},
        true
    )
}
export const getContactUsList = () => {
    return find('MtContactUs',{ where: {}, order: "id desc" })
}
export const getContactUs = (contactId) => {
    return find('MtContactUs', { "where": { "id": contactId } })
}
export const UpdateContactUs = (id,data) => {
    return upsertPatch(`MtContactUs/${id}`, data)
}
export const findContactus=(filterValue) => {
    const filter = { where: {},order: "id desc" };
    const value=filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
    if (filterValue) {
        filter.where.or = [{ contactName:{like:value}},{emailId:{like:value}}];
    }
    return find('MtContactUs', filter)
}
export const findHelpDesks=(filterValue) => {
    const filter = { where: {},order: "id desc" };
    const value=filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
    if (filterValue) {
        filter.where.or = [{ phoneNumber:{like:value}},{ mtUserId:{like:value}}];
    }
    return find('MtHelpDesks', filter)
}
export const uploadFile = (fileData,bucketName,folder='') => {
    const formData = new FormData();
    // Update the formData object 
    const today = new Date();
    const datestring = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    formData.append(
        "myFile",
        fileData,
        folder + datestring+fileData.name
    );
    return apiKit.post(`/AWSContainers/${bucketName}/upload?access_token=${getLocalData('accessToken')}`, formData)
}
export const createUser = (data) => {
    return create('MtUsers', data)
}
export const getAllVehicle = (filterValue,status) => {
    const filter = { where: {},order: "id desc" };
    if (filterValue || status) {
        if (filterValue && status) {
            filter.where.and = [{ vehicleType:filterValue},{status:status}];
        }
        else{filter.where.or = [{ vehicleType:filterValue},{status:status}];}
    }
    return find('MtVehicles', filter)
}
export const findVehicle=(filterValue) => {
    const filter = { where: {},order: "id desc" };
    if (filterValue) {
        filter.where.or = [{ vehicleNumber:{nlike:filterValue}},{ vehicleType:{nlike:filterValue}}];
    }
    return find('MtVehicles', filter)
}
export const getVehicleDetailsbyId = (id) => {
    return find('MtVehicles', { "where": { "id": id } })
}
export const UpdateVehicleDetails = (id,data) => {
    return upsertPatch(`MtVehicles/${id}`, data)
}
export const getGpsDeviceData=(filterValue) => {
    const filter = { where: {},order: "id desc" };
    if (filterValue) {
        filter.where.and = [{ vehicle_number:{like:filterValue}}];
    }
    return find('MtLastDeviceData', filter)
}
export const findOneVehicle = (id) => {
    return find('MtVehicles',{ "where": { "id": id } })
}
export const createRateCard = (data) => {
    return create('MtRateCards', data)
}
export const findRateCardList = (locationValue,vehicleType) => {
    const filter={where:{},order:"id desc"};
    if(locationValue!=null && vehicleType!=null)
    {
        filter.where.and=[{location:locationValue},{vehicleType:vehicleType}];
    }
    else
    {
        filter.where.and=[{location:locationValue}];
    }
    return find('MtRateCards', filter)
}
export const getSystemListSubLocality = (city) => {
    const filter={where:{},order:"id desc"};
    filter.where.and=[{dbCity:city},{listType:"city"}];
    return find('MtSystemLists', filter)
}
export const UpdateSubLocation = (id,data) => {
    return upsertPatch(`MtSystemLists/${id}`, data)
}
export const deleteUserById=(userId)=>{
    return deleteById('MtUsers',userId)
}
export const userSubscriberList=(value)=>{
    const filter={where:{},include:["mtBusinessProfiles"],order: "id desc"};
    if(value=="A")
    {
        filter.where.and=[{status:"A"},{planMode:value}];
    }
    else
    {
        filter.where.or=[{planMode: {nlike: 'A'}},{planMode:value}];  
    }
    return find('MtUsers', filter)
}
export const todayUserList=()=>{
    var now = new Date();
    return find('MtUsers', { "where": {"status": "A","and": [{ "codeCreatedAt": { "eq": now } }]}})
}
export const filterBetweenDateReport = (startDate, endDate, cStatus) => {
    return new Promise((resolve, reject) => {
        find('MtUsers', { "where": {"planMode": cStatus, "and": [{ "codeCreatedAt": { "gte": startDate } }, { "codeCreatedAt": { "lte": endDate } }] } })
            .then(data => {
                resolve(data);
            })
    })
}
