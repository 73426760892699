import React,{ useState }  from 'react';
import { Row, Col, FormGroup, Button, Label} from "reactstrap";
import SelectList from "../Components/SelectList";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MtFormLayout from '../Components/MtFormLayout';
import { useHistory } from 'react-router-dom';
import MtSweetAlert from '../Components/MtSweetAlert';
import {createUser} from '../../services/api/api-service';
import _, { repeat } from 'lodash';
const AddUser = () => {
    const [stateValue, setStateValue] = useState(null);
    const [listingValue, setListingValue] = useState(null);
    function betweenRandum(min, max) {  
        return Math.floor(
          Math.random() * (max - min) + min
        )
      }
    let history = useHistory();
    const goBack = () => {
        history.goBack()
    }
     function addMonths(numOfMonths, date = new Date()) {
        date.setMonth(date.getMonth() + numOfMonths);
        return date;
      }
    const expiryDateResult = addMonths(12);
    //alert 
    const [conformMsg, setConformMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Something Wrong !");
    const [errorStatus, setErrorStatus] = useState(false);
    const [defaultPassword]=useState(betweenRandum(100000,1000000).toString());
    //handleSubmit
    const [submitValue,setSubmitValue]=useState([]);
    const handleValidSubmit = (event, values, actions) => {
      const currentDate=new Date();
        const valueData=[]
        valueData.push({
            phoneVerified:true,
            contactName:values.contactName,
            userType:values.userType,
            username:values.contactNumber,
            password:defaultPassword,
            email:values.email,
            contactNumber:values.contactNumber,
            isTermsAgreed: true,
            emailVerified:false,
            status:"A",
            planMode:"A",
            planExpairyDate:expiryDateResult,
            codeCreatedAt:currentDate,
            state:stateValue.label,
            businessTypeTag:listingValue.value
        })
        //console.log(valueData);
        if(values.userType=="Select User Type")
        {
            setErrorStatus(true);
            setAlertMsg("User Type Not Selected !");
        }
        else
        {
            setSubmitValue(valueData[0]);
            setConformMsg(true);
        }
    }
    const submitHandler = () => {
        createUser(submitValue).then(data=>{
            if (!_.isEmpty(data)) {
             setSuccessMsg(true);
             console.log(data);
            }
        }).catch(e=>{
            alertMsg(e);
        })
    }
    return (
        <React.Fragment>
            <MtFormLayout
                breadcrumbsTitle="User"
                breadcrumbItem="Add User"
                title="Add Mt User"
                subTitle="Add New User Movers Account">
                <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} >
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Contact name <strong style={{color:'red'}}>*</strong></Label>
                                <AvField
                                    name="contactName"
                                    placeholder="Contact Name"
                                    type="text"
                                    errorMessage="Enter Contact Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Contact number <strong style={{color:'red'}}>*</strong></Label>
                                <AvField
                                    name="contactNumber"
                                    placeholder="Contact Number"
                                    type="text"
                                    errorMessage="Enter Contact Number"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Email <strong style={{color:'red'}}>*</strong></Label>
                                <AvField
                                    name="email"
                                    placeholder="Email"
                                    type="text"
                                    errorMessage="Enter Email"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <Label htmlFor="validationCustom01">User Type <strong style={{color:'red'}}>*</strong></Label>
                               
                                <AvField type="select" name="userType" helpMessage="Select User Type">
                                    <option>Select user type</option>
                                    <option>MTGPS</option>
                                    {/* <option>MTSUBSCRIBE</option> */}
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                               <SelectList label="State" name="state" type="state" setStateValue={setStateValue} stateValue={stateValue}></SelectList>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                               <SelectList label="Category" name="businessTypeTag" type="listtype" setStateValue={setListingValue} stateValue={listingValue}></SelectList>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            {errorStatus &&
                                <div className="alert alert-danger fade show" role="alert">{alertMsg}</div>
                            }
                        </Col>
                        <Col md="12">
                            <Button color="success" type="submit">Submit</Button>
                            {conformMsg ? (
                                <MtSweetAlert
                                    alertTitle="Add Business Profile"
                                    alertConfirmBtnText="Submit"
                                    alertHandler={submitHandler}>
                                    Do you submit data
                                </MtSweetAlert>
                            ) : null}
                            {successMsg ? (
                                <MtSweetAlert
                                    alertTitle="Business Profile"
                                    alertConfirmBtnText="Go Back"
                                    alertHandler={goBack} >
                                    Account is Create successfully Mtuser password is:-{defaultPassword}
                                </MtSweetAlert>
                            ) : null}
                        </Col>
                    </Row>
                </AvForm>
            </MtFormLayout>
        </React.Fragment>
    );
}
export default AddUser;