import React, {useEffect, useState } from 'react';
import {AvField } from "availity-reactstrap-validation";
import {getSystemListType} from '../../services/api/api-service';
import _ from 'lodash';
const VehicleType =(props)=> {
    const [vehicleTypeList, setVehicleTypeList] = useState([]);
    const handlerfilter=(event,values)=>{
        props.getVehicleType(values);
    }
    useEffect(() => {
        getSystemListType('vehicleType').then(result => {
            setVehicleTypeList(result)
        })
    },[])
        return (
            <React.Fragment>
               <AvField type="select" name="vehicleType" label="Vehicle Type" onChange={handlerfilter} helpMessage={_.get(props,"valueSelect")?props.valueSelect:"select Vehicle Type"}>
               <option value={false}>--Select--</option>
               {vehicleTypeList.map((project, key) =>
                    <option  key={key} value={project.value}>{project.label}</option> 
                 )} 
                </AvField>
            </React.Fragment>
        );
}

export default VehicleType;