import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, FormGroup, CardBody, Table, Label,Badge, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from "react-switch";
import _ from 'lodash'
import { getHelp, getHelpDesks, findHelpDesks,UpdateHelpDesk } from "../../services/api/api-service"
import Pagination from '../Components/pagination';
const Help = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const [resolveDetails, setResolveDetails] = useState(null)
    const [switchValue, setSwitchValue] = useState(true);
    const [requestId,setRequestId]=useState(null);
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [userListData, setUserListData] = useState([]);
    const [modal, setModal] = useState(false);
    const [helpList, sethelpList] = useState([]);
    const handleValidSubmit = (event, values) => {
        findHelpDesks(values.searchName).then(data => {
            if (!_.isEmpty(data)) {
                setUserListData(data);
                console.log(data)
            }
        })
    }
    const handleResolveSubmit = (event, value) => {
        const datavalue = {
            resolveDetails:resolveDetails,
            resolveStatus:switchValue
          }
        console.log(datavalue);
        UpdateHelpDesk(requestId,datavalue).then(result=>{
            window.location.reload();
            setModal(false);
        })
    }
    //Text area Onchange Handle
    const handleChange = (event) => {
        setResolveDetails(event.target.value)
    }
    const showDetails = (userId) => {
        setRequestId(userId);
        getHelp(userId).then(data => {
            if (!_.isEmpty(data)) {
                sethelpList(data)
                setModal(true);
            }
        })
    }
    useEffect(() => {
        getHelpDesks().then(data => {
            if (!_.isEmpty(data)) {
                //console(data)
                setUserListData(data);
            }
        }).catch(e => {
            console.log("Error in user List:", e);
        })
    }, [])

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = userListData.slice(indexOfFirstPost, indexOfLastPost);
    const togglemodal = () => {
        setModal(false)
    }
    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
      No
            </div>
        );
    };
    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
      Yes
            </div>
        );
    };
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Listing" breadcrumbItem="User List" />
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="5">
                                                    <AvField name="searchName" label="Search User" required /></Col>
                                                <Col md="2">
                                                    <FormGroup className="mb-0 mt-4 ">
                                                        <Button color="primary" className="mr-1">
                                                            Search
                                                            </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                        <Row>
                            <Col lg="12">
                                <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {userListData.length} entries</div>
                            </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">User Type</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Phone No.</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPosts.map(item => (
                                                    <tr key={item.id} >
                                                        <td>
                                                            {item.title}
                                                        </td>
                                                        <td>
                                                            {item.userType}
                                                        </td>
                                                        <td>
                                                        {new Date(item.createdAt).toLocaleString()}
                                                        </td>
                                                        <td>
                                                            {item.phoneNumber}
                                                        </td>
                                                    <td>
                                                            <Badge className={_.get(item,"resolveStatus")?item.resolveStatus ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-danger":"font-size-12 badge-soft-danger"} color={_.get(item,"resolveStatus")?item.resolveStatus ? "success" : "danger":"danger"} pill>{_.get(item,"resolveStatus")?item.resolveStatus ?"Resolved":"Unresolved":"Unresolved"}</Badge>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => showDetails(item.id)}
                                                                id="edittooltip2"
                                                                className="mr-3 text-primary"
                                                            >
                                                                <i className="mdi mdi-eye font-size-18" style={{ color: 'white', }} ></i>

                                                            </Button>

                                                        </td>
                                                    </tr>
                                                )

                                                )
                                                }
                                            </tbody>

                                        </Table>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={userListData.length}
                                    paginate={paginate} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            <Modal isOpen={modal} size="lg" role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={togglemodal}>
                <div className="modal-content">
                    <AvForm className="needs-validation" onValidSubmit={handleResolveSubmit} >
                        <ModalHeader toggle={togglemodal}>
                            Details
                            </ModalHeader >
                        <ModalBody>
                            <div className="table-responsive">
                                {helpList.map(item => (
                                    <Table className="table table-sm m-0 table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Title:</th>
                                                <td>{item.title}</td>
                                                <th scope="row">User Type:</th>
                                                <td colSpan="3">{item.vehicleNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone Number:</th>
                                                <td>{item.phoneNumber}</td>
                                                <th scope="row">MT UserId:</th>
                                                <td>{item.mtUserId}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Details:</th>
                                                <td colSpan={3}>{item.detail}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                ))}
                            </div>

                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">Resolve Details</Label>
                                        <Input
                                            type="textarea"
                                            id="resolveDetails"
                                            onChange={handleChange}
                                            maxLength="225"
                                            rows="3"
                                            placeholder="User Help Request Resolve Details"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">Resolve Status</Label>
                                        <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            className="mr-2 ml-3"
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => switchValue ? setSwitchValue(false) : setSwitchValue(true)}
                                            checked={switchValue}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit">Resolve</Button>
                            <Button type="button" color="secondary" onClick={togglemodal}>Close</Button>
                        </ModalFooter>
                    </AvForm>
                </div>

            </Modal>

        </>
    );
}
export default Help;