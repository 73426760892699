import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, Table, CardSubtitle, Modal } from "reactstrap"
import { Link } from "react-router-dom";
import globelData from '../../../services/global-data'
import ResetPassword from '../../Components/resetPassword';
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import {changeStatus } from "../../../services/api/api-service";
import MtModelBox from '../../Components/MtModelBox';
import _ from 'lodash'
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const List = (props) => {
    const { ExportCSVButton } = CSVExport;
    const [conformMsg, setConformMsg] = useState(false);
    const [resposeValue, setResposeValue] = useState(null);
    const [modal, setModal] = useState(false);
    const [currentUser, SetCurrentUser] = useState(null);
    const [userVerify, setUserVerify] = useState(true);
    const [verifyStatus, setverifyStatus] = useState(true);
    const [businessStatus, setBusinessStatus] = useState(true)

    const { SearchBar } = Search;
    const productData = props.listData;

    //end pagin
    const [modal2, setModal2] = useState(false);
    const [userDetails, setUserDetails] = useState([]);

    const resetPasswordHandler = (userId) => {
        SetCurrentUser(userId)
        setConformMsg(true)
    }
    const activateUser = (data) => {
        setUserDetails(data);
        setverifyStatus(_.get(data, "status") ? data.status == "A" ? false : true : true)
        setBusinessStatus(_.get(data.mtBusinessProfiles[0], "companyName") ? true : false)
        SetCurrentUser(data.id)
        setModal(true);
    }
    const togglemodal = () => {
        setModal(false)
    }
    const togglemodal2 = () => {
        setModal2(false)
    }
    const verifyUserAc = () => {
        changeStatus(currentUser, "A").then(data => {
            if (!_.isEmpty(data)) {
                setResposeValue(data.response);
                setUserVerify(false)
                setverifyStatus(false);
            }
        })
    }
    const deactiveUserAc = () => {
        changeStatus(currentUser, "I").then(data => {
            if (!_.isEmpty(data)) {
                setResposeValue(data.response);
                setUserVerify(true)
                setverifyStatus(true);
            }
        })
    }
    const columns = [{
        dataField: 'contactName',
        text: 'Contact Name',
        sort: true,
    }, {
        dataField: 'username',
        text: 'Mobile Number',
        sort: true
    }, {
        dataField: 'businessTypeTag',
        text: 'User Type'
    },
    {
        dataField: "codeCreatedAt",
        text: "Reg. Date",
        sort: true,
        formatter: (cellContent, row) => {
            if (row.codeCreatedAt)
                return (
                    <span className="font-size-9 badge-soft-info badge badge-info badge-pill">{new Date(row.codeCreatedAt).toLocaleString('en-GB')}</span>
                )
        }
    },
    {
        dataField: "planExpairyDate",
        text: "Plan Expair",
        sort: true,
        formatter: (cellContent, row) => {
            if (row.planMode == "A") {
                if (row.planExpairyDate)
                    return (
                        <span className="font-size-9 badge-soft-danger badge badge-danger badge-pill">{new Date(row.planExpairyDate).toLocaleString('en-GB')}</span>

                    )
            }

        }
    },
     {
        dataField: 'state',
        text: 'State',
        sort: true
    },
    {
        dataField: "planMode",
        text: "Plan Status",
        formatter: (cellContent, row) => {
            if (row.planMode == "A")
                return (
                    <span className="font-size-9 badge-soft-success badge badge-success badge-pill">Active</span>
                );
            return (
                <span className="font-size-9 badge-soft-danger badge badge-danger badge-pill"> Inactive</span>
            );
        }
    },
    {
        dataField: "",
        text: "Business Profile",
        formatter: (cellContent, row) => {
            if (_.get(row,"mtBusinessProfiles")?row.mtBusinessProfiles.length!=0?true:false:false)
                return (
                        <span className="font-size-9 badge-soft-success badge badge-success badge-pill">Complete</span>
                );
            return (
                <h5>
                    <span className="font-size-9 badge-soft-danger badge badge-danger badge-pill"> Pending</span>
                </h5>
            );
        }
    },
    {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
            if (row.status == "A")
                return (
                        <span className="font-size-9 badge-soft-success badge badge-success badge-pill">Active</span>
                 
                );
            return (
                    <span className="font-size-9 badge-soft-danger badge badge-danger badge-pill"> Inactive</span>
            );
        }
    },
    {
        dataField: "",
        text: "",
        sort: false,
        formatter: rankFormatter,
        headerAttrs: { width: 50 },
        attrs: { width: 50, class: "EditRow" }
    }
    ];
    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    lineHeight: "normal"
                }}>

                <Button
                    color="primary"
                    id="edittooltip2"
                    style={{backgroundColor:"#0066cc",color:"#fff",padding:3}}
                    onClick={() => handleClick(cell, row, rowIndex)}
                >
                    <i className="mdi mdi-eye font-size-6"></i>

                </Button>
            </div>
        );
    }
    const handleClick = (cell, row, rowIndex) => {
        setUserDetails(row);
        setModal2(true)
    }
    // Table Data
    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true
      };
    return (
        <React.Fragment>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">{props.title}</CardTitle>
                            <CardSubtitle>Find user list</CardSubtitle>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='id'
                                        columns={columns}
                                        data={productData}
                                        search
                                        exportCSV={{
                                            fileName: "file-exportCSV-MoversTrip.csv",
                                            onlyExportFiltered:true,
                                            onlyExportSelection: true,
                                            exportAll: true
                                        }}
                                        
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row>
                                                    <Col xl="12">
                                                    <hr />
                                                    <SearchBar 
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                    <ExportCSVButton  className="btn btn-info mr-2 ml-5" {...toolkitProps.csvProps }>
                                                                <i className="bx bx-upload search-icon" /> Download List</ExportCSVButton>
                                                                <SizePerPageDropdownStandalone  {...paginationProps} />
        <hr />
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                bordered={true}
                                                                selectRow={ selectRow }
                                                                defaultSorted={defaultSorted}
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />
                                                             
                                                             <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {modal &&
                <MtModelBox sizeValue="lg" titleLabel="User Details" modelValue={modal} modelSetValue={setModal}>
                    {userVerify &&
                        <div className="table-responsive">
                            {userDetails &&
                                <Table className="table table-sm m-0 table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Contact Name:</th>
                                            <td>{userDetails.contactName}</td>
                                            <th scope="row">User Type:</th>
                                            <td colSpan="3">{userDetails.userType}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">UserName:</th>
                                            <td>{userDetails.userType}</td>
                                            <th scope="row">Email:</th>
                                            <td>{userDetails.email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Verification Code:</th>
                                            <td>{userDetails.verificationCode}</td>
                                            <th scope="row">Last Login:</th>
                                            <td>{userDetails.lastLogin}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Phone Number Verified:</th>
                                            <td>{userDetails.phoneVerified ? "Yes" : "No"}</td>
                                            <th scope="row">Registration Date:</th>
                                            <td>{new Date(_.get(userDetails, "codeCreatedAt") ? userDetails.codeCreatedAt : "NULL").toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                            {userDetails &&
                                <>
                                    <Table className="table table-sm m-0 table">
                                        {businessStatus &&
                                            <tbody>

                                                <tr style={{ backgroundColor: "#d9dadb" }}>
                                                    <th scope="row" colSpan="3"><h4 style={{ color: "black", marginTop: 1, }}>Business Profile</h4></th>
                                                    <td> <Link
                                                        color="info"
                                                        className="btn btn-info mr-2"
                                                        to={`/UpdateBusinessList?businessId=${_.get(userDetails.mtBusinessProfiles[0], "id") ? userDetails.mtBusinessProfiles[0].id : "NULL"}`}
                                                        id="edittooltip2"
                                                    >Update Business Profile
                                                    </Link></td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="4">
                                                        <img src={_.get(userDetails.mtBusinessProfiles[0], "businessCard") ? userDetails.mtBusinessProfiles[0].businessCard != "" ? userDetails.mtBusinessProfiles[0].businessCard : globelData.defaultCard : globelData.defaultCard} style={{ width: "100%", height: 200, borderRadius: 10, }} alt="Visiting Card" />

                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Company Name:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "companyName") ? userDetails.mtBusinessProfiles[0].companyName : "NULL"}</td>
                                                    <th scope="row">business Type:</th>
                                                    <td colSpan="3">{_.get(userDetails.mtBusinessProfiles[0], "businessTypeTag") ? userDetails.mtBusinessProfiles[0].businessTypeTag : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Owner Name:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "businessTypeTag") ? userDetails.mtBusinessProfiles[0].businessTypeTag : "NULL"}</td>
                                                    <th scope="row">Owner Phone:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "ownerPhone") ? userDetails.mtBusinessProfiles[0].ownerPhone : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Contact Person:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "contactPerson") ? userDetails.mtBusinessProfiles[0].contactPerson : "NULL"}</td>
                                                    <th scope="row">Contact Number:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "contactNumber") ? userDetails.mtBusinessProfiles[0].contactNumber : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Website:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "website") ? userDetails.mtBusinessProfiles[0].website : "NULL"}</td>
                                                    <th scope="row">Business Email:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "businessEmail") ? userDetails.mtBusinessProfiles[0].businessEmail : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vecile Available For:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "routeVehicleLoaded") ? userDetails.mtBusinessProfiles[0].routeVehicleLoaded : "NULL"}</td>
                                                    <th scope="row">Special Services:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "specialServices") ? userDetails.mtBusinessProfiles[0].specialServices : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Address:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "address") ? userDetails.mtBusinessProfiles[0].address : "NULL"}</td>
                                                    <th scope="row">pin:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "pin") ? userDetails.mtBusinessProfiles[0].pin : "NULL"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">City:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "city") ? userDetails.mtBusinessProfiles[0].city : "NULL"}</td>
                                                    <th scope="row">State:</th>
                                                    <td>{_.get(userDetails.mtBusinessProfiles[0], "state") ? userDetails.mtBusinessProfiles[0].state : "NULL"}</td>
                                                </tr>
                                            </tbody>
                                        }
                                    </Table>
                                </>
                            }
                            {_.isEmpty(userDetails) &&
                                <div class="spinner-border text-dark m-1" role="status"><span class="sr-only">Loading...</span></div>
                            }
                        </div>
                    }
                    {!userVerify &&
                        <div className="table-responsive">
                            <h1 style={{ color: 'green' }}>{resposeValue}</h1>
                        </div>
                    }
                    {verifyStatus ?
                        <Button type="button" className="btn btn-success mr-2" color="success" onClick={verifyUserAc}>Activate</Button> :
                        <Button type="button" className="btn btn-danger mr-2" color="denger" onClick={deactiveUserAc}>Deactive</Button>}
                    {!businessStatus ?
                        <Link
                            color="info"
                            className="btn btn-info mr-2"
                            to={`/AddBusinessProfile?mtUserId=${currentUser}`}
                            id="edittooltip2"
                        >Create Business Profile
                        </Link> : null
                    }
                    <Button type="button" color="secondary" onClick={togglemodal}>Close</Button>
                </MtModelBox>
            }
            {conformMsg ? <ResetPassword userid={currentUser} /> : null}


            <Modal isOpen={modal2} size="sm" toggle={togglemodal2} centered>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Action</h5>
                    <button
                        type="button"
                        onClick={togglemodal2}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-grid gap-2">
                        {userDetails &&
                            <>
                                <Button
                                    color="primary"
                                    
                                    onClick={() => activateUser(userDetails)}

                                    className={_.get(userDetails, "status") ? userDetails.status == "A" ? "invisible btn btn-defulte  btn btn-defulte mb-1 w-100" : "btn btn-defulte  btn btn-defulte mb-1 w-100" : "btn btn-defulte  btn btn-defulte mb-1 w-100"}
                                >
                                    Active
                                </Button>
                                <Button

                                    color="primary"
                                    onClick={() => activateUser(userDetails)}
                                    id="edittooltip2"
                                    className="btn btn-success  btn btn-success mb-1 w-100"
                                >
                                    View

                                </Button>
                                <Link
                                    className="btn btn-secondary  btn btn-secondary mb-1 w-100"
                                    color="info"
                                    to={`/UpdateDetails?mtUserId=${userDetails.id}`}
                                    id="edittooltip2"
                                >Update
                                </Link>
                                <Button
                                    className="btn btn-secondary  btn btn-secondary mb-1 w-100"
                                    color="info"
                                    onClick={() => resetPasswordHandler(userDetails.id)}
                                    id="edittooltip2"
                                >Reset Password
                                </Button>
                            </>
                        }

                    </div>
                </div>
            </Modal>

        </React.Fragment>
    )
}
export default List
