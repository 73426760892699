import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, FormGroup, CardTitle, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import _ from 'lodash'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getBusinessListState, findBusiness} from '../../services/api/api-service';
//Import Image
import BusinessDataTable from './ListComponents/BusinessDataTable';
const TransporterSearch = (props) => {
    const [businessList, setbusinessList] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    useEffect(() => {
        getBusinessListState('Transporter', stateValue).then(data => {
            if (!_.isEmpty(data)) {
                setbusinessList(data)
            }
        })

    }, [stateValue])
   
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Listing" breadcrumbItem="Transporter List" />
                    <BusinessDataTable title={'Register transporter list'} listData={businessList}/>              
                </Container>
            </div>
        </React.Fragment>
    );
}
export default TransporterSearch;