import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Table,Card, CardBody, FormGroup,CardTitle, Button} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Cards
import VehicleLiveList from "../Components/VehicleLiveList";
import VehicleType from "../Components/VehicleType";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Image
class VehicleTypelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,Vehicle: "RJ01FS5356", vehicletype: "Pickup", Location: "Ajmer,Rajasthan 305001",destination: "All India",contactName:"Rakesh Sharma",contactNumber:"9101525155",
                },
            ]
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Vehicle" breadcrumbItem="Vehicle Type List" />
                        <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Search By Filter</CardTitle>
                    <AvForm>
                    <Row>
                    <Col md="2">
                        <VehicleType />
                      </Col>
                       <Col md="4">
                       <AvField
                        name="searchText"
                        label="Keyword Search"
                        placeholder="Search..."
                        helpMessage="Exp:Vehicle No, City, State, Vehicle type"
                        type="searchText"
                      /></Col>
                       <Col md="2">
                       <FormGroup className="mb-0 mt-4 ">
                        <div>
                          <Button type="submit" color="primary" className="mr-1">
                            Search
                        </Button>{" "}
                         
                        </div>
                      </FormGroup>
                           </Col>
                        </Row>
                     
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
                        <Row>
                        <Col lg="12">
                <div class="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                    </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Vehicle NO</th>
                                                    <th scope="col">Vehicle Type</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Destination</th>
                                                    <th scope="col">Contact Name</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <VehicleLiveList projects={this.state.projects} />
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
              
              <Col lg="12">
              <nav aria-label="..." style={{float:'right'}}>
                                                 <ul className="pagination">
                                                     <li className="page-item disabled">
                                                         <Link className="page-link" to="#" tabIndex="-1">Previous</Link>
                                                     </li>
                                                     <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                                     <li className="page-item active">
                                                         <Link className="page-link" to="#">2 <span className="sr-only">(current)</span></Link>
                                                     </li>
                                                     <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                                     <li className="page-item">
                                                         <Link className="page-link" to="#">Next</Link>
                                                     </li>
                                                 </ul>
              </nav>
             </Col>
            
         </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default VehicleTypelist;