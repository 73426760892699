import React, { Component } from "react";
import { Row, Col,Container } from "reactstrap";
import VehicleForm from "../Components/VehicleForm"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
 const VehicleRegister=(props)=> {
    const query = new URLSearchParams(props.location.search);
    const mtUserId = query.get('mtUserId')
    return (
      <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                    <Breadcrumbs title="Vehicle" breadcrumbItem="Vehicle Register" />
                        <Row>
                            <Col lg="12">
                               <VehicleForm
                                title="Vehicle Registration"
                                Type="New"
                                userId={mtUserId} />
                            </Col>
                        </Row>

                    </Container>
                </div>
      </React.Fragment>
    );
 
}

export default VehicleRegister;
