import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, FormGroup, CardBody, Table, Label,Badge, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from "react-switch";
import _ from 'lodash'
import { getContactUs, getContactUsList, findContactus,UpdateContactUs } from "../../services/api/api-service"
import Pagination from '../Components/pagination';
const ContactUs = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const [resolveDetails, setResolveDetails] = useState(null)
    const [switchValue, setSwitchValue] = useState(true);
    const [requestId,setRequestId]=useState(null);
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [contactUsData, setcontactUsData] = useState([]);
    const [modal, setModal] = useState(false);
    const [helpList, sethelpList] = useState([]);
    const handleValidSubmit = (event, values) => {
        findContactus(values.searchName).then(data => {
            if (!_.isEmpty(data)) {
                setcontactUsData(data);
                console.log(data)
            }
        })
    }
    const handleResolveSubmit = (event, value) => {
        const datavalue = {
            resolveDetails:resolveDetails,
            status:switchValue
          }
         UpdateContactUs(requestId,datavalue).then(result=>{
            ///console.log(result)
            window.location.reload();
            setModal(false);
        })
    }
    //Text area Onchange Handle
    const handleChange = (event) => {
        setResolveDetails(event.target.value)
    }
    const showDetails = (userId) => {
        setRequestId(userId);
        getContactUs(userId).then(data => {
            if (!_.isEmpty(data)) {
                sethelpList(data)
                setModal(true);
            }
        })
    }
    useEffect(() => {
        getContactUsList().then(data => {
            if (!_.isEmpty(data)) {
                setcontactUsData(data);
            }
        }).catch(e => {
            console.log("Error in user List:", e);
        })
    }, [])

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const dataList = contactUsData.slice(indexOfFirstPost, indexOfLastPost);
    const togglemodal = () => {
        setModal(false)
    }
    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
      No
            </div>
        );
    };
    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
      Yes
            </div>
        );
    };
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Listing" breadcrumbItem="User List" />
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="5">
                                                    <AvField name="searchName" label="Search User" required /></Col>
                                                <Col md="2">
                                                    <FormGroup className="mb-0 mt-4 ">
                                                        <Button color="primary" className="mr-1">
                                                            Search
                                                            </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                        <Row>
                            <Col lg="12">
                                <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {contactUsData.length} entries</div>
                            </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Contact Name</th>
                                                    <th scope="col">Contact Type</th>
                                                    <th scope="col">Contact Number</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Subject</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataList.map(item => (
                                                    <tr key={item.id} >
                                                        <td>
                                                            {item.contactName}
                                                        </td>
                                                        <td>
                                                            {item.contactType}
                                                        </td>
                                                        <td>
                                                            {item.contactNumber}
                                                        </td>
                                                        <td>
                                                            {new Date(item.createdAt).toLocaleString()}
                                                        </td>
                                                        <td>
                                                            {item.subject}
                                                        </td>
                                                        <td>
                                                            <Badge className={_.get(item,"status")?item.status ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-danger":"font-size-12 badge-soft-danger"} color={_.get(item,"status")?item.status ? "success" : "danger":"danger"} pill>{_.get(item,"status")?item.status ?"Resolved":"Unresolved":"Unresolved"}</Badge>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => showDetails(item.id)}
                                                                id="edittooltip2"
                                                                className="mr-3 text-primary"
                                                            >
                                                                <i className="mdi mdi-eye font-size-18" style={{ color: 'white', }} ></i>

                                                            </Button>

                                                        </td>
                                                    </tr>
                                                )

                                                )
                                                }
                                            </tbody>

                                        </Table>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={contactUsData.length}
                                    paginate={paginate} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            <Modal isOpen={modal} size="lg" role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={togglemodal}>
                <div className="modal-content">
                    <AvForm className="needs-validation" onValidSubmit={handleResolveSubmit} >
                        <ModalHeader toggle={togglemodal}>
                            Details
                            </ModalHeader >
                        <ModalBody>
                            <div className="table-responsive">
                                {helpList.map(item => (
                                    <Table className="table table-sm m-0 table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Contact Name:</th>
                                                <td>{item.contactName}</td>
                                                <th scope="row">Email Id:</th>
                                                <td colSpan="3">{item.emailId}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Subject:</th>
                                                <td>{item.subject}</td>
                                                <th scope="row">Date:</th>
                                                <td>{new Date(item.createdAt).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Message:</th>
                                                <td colSpan={3}>{item.message}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Resolve Details:</th>
                                                <td colSpan={3}>{item.resolveDetails}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                ))}
                            </div>

                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">Resolve Details</Label>
                                        <Input
                                            type="textarea"
                                            id="resolveDetails"
                                            onChange={handleChange}
                                            maxLength="225"
                                            rows="3"
                                            placeholder="User Help Request Resolve Details"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="validationCustom01">Resolve Status</Label>
                                        <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            className="mr-2 ml-3"
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => switchValue ? setSwitchValue(false) : setSwitchValue(true)}
                                            checked={switchValue}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit">Resolve</Button>
                            <Button type="button" color="secondary" onClick={togglemodal}>Close</Button>
                        </ModalFooter>
                    </AvForm>
                </div>

            </Modal>

        </>
    );
}
export default ContactUs;