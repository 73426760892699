import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";
// User Chagne password
import ChangePassword from "../pages/Authentication/ChangePassword";
// User UpdateDetails
import UpdateDetails from "../pages/User/UpdateDetails";
import MtUsersList from "../pages/User/mtUsersList";
import MtgpsUserList from "../pages/User/mtgpsUserList";
import AddUser from "../pages/User/addUser";
// User Vehicle
import GpsUser from "../pages/Vehicle/GpsUser";
import UserVehicleList from "../pages/Vehicle/UserVehicleList";
import PostLoad from "../pages/Vehicle/PostLoad";
import MemberLoadPost from "../pages/Vehicle/MemberLoadList";
import EditLoadPost from "../pages/Vehicle/EditLoadPost";
import PostLoadlist from "../pages/Vehicle/PostLoadList";
import VehicleRegister from "../pages/Vehicle/VehicleRegister";
import SearchVehicleDetails from "../pages/Vehicle/SearchVehicleDetails";
import VehicleList from "../pages/Vehicle/VehicleList";
import VehicleType from "../pages/Vehicle/VehicleTypelist";
import EditVehicleDetails from "../pages/Vehicle/EditVehicleDetails";
import RateCard from "../pages/Vehicle/RateCard";
import NeedLoadList from "../pages/Vehicle/NeedLoadList";
import GpsDeviceDataList from "../pages/Vehicle/GpsDeviceDataList"
//Listing
import AddBusinessProfile from "../pages/Listing/AddBusinessProfile";
import TransporterList from "../pages/Listing/TransporterSearch";
import PackersAndMovers from "../pages/Listing/PackersAndMoversList";
import UpdateBusinessList from "../pages/Listing/updateBusinessList";
// User Notification
import Notification from "../pages/Dashboard/notificationList";
// Transaction
import Transaction from "../pages/Transactions/Transaction";
// Live location Map
import NearVehicle from "../pages/Map/NearVehicle";
import LiveVehicle from "../pages/Map/LiveVehicle";
import vehicleLocation from "../pages/Map/VehicleLocation";
// pricing
import Pricing from "../pages/Subscription/Pricing";
import Kyc from "../pages/Subscription/Kyc";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import OtpVerification from "../pages/Authentication/OtpVerification";
//Help
import Faq from "../pages/Other/Faq";
import Help from "../pages/Other/Help";
import ContactUs from "../pages/Other/ContactUs";
import SystemData from "../pages/Other/SystemData";
//Report
import SubscriberUser from "../pages/Report/subscriber-user";
import UnsubcriberUser from "../pages/Report/unsubscriber-user";
import TodayUser from "../pages/Report/today-user";
  // Dashboard
import Dashboard from "../pages/Dashboard/index";
const authProtectedRoutes = [
	{ path: "/dashboard", component:Dashboard},
	//profile
	{ path: "/profile", component:UserProfile},
	{ path: "/UpdateDetails", component:UpdateDetails},
	//Users
	{path:"/mtUsersList",component:MtUsersList},
	{path:"/mtgpsUserList",component:MtgpsUserList},
	{path:"/AddUser",component:AddUser},
	//changpassword
	{ path: "/changepassword", component: ChangePassword },
	//Vehicle 
	{ path: "/postload", component: PostLoad },
	{ path: "/memberLoadPost", component: MemberLoadPost },
	{ path: "/editLoadPost", component: EditLoadPost },
	{ path: "/postloadlList", component: PostLoadlist },
	{ path: "/vehicleRegister", component: VehicleRegister },
	{ path: "/editVehicleDetails", component: EditVehicleDetails },
	{ path: "/searchVehicleDetails", component:  SearchVehicleDetails },
	{ path: "/vehicleList", component:  VehicleList },
	{ path: "/vehicleTypeList", component: VehicleType },
	{ path: "/RateCard", component: RateCard},
	{ path: "/NeedLoadList", component: NeedLoadList},
	{path:"/GpsUser",component:GpsUser},
	{path:"/UserVehicleList",component:UserVehicleList},
	{path:"/GpsDeviceDataList",component:GpsDeviceDataList},
	//Listing 
	{ path: "/AddBusinessProfile", component: AddBusinessProfile },
	{ path: "/transporterList", component: TransporterList },
	{ path: "/packersAndMovers", component: PackersAndMovers },
	{ path: "/UpdateBusinessList", component: UpdateBusinessList },
	//OtherPages 
	{ path: "/faq", component: Faq},
	{ path: "/help", component: Help},
	{ path: "/ContactUs", component: ContactUs},
	{path:"/SystemData",component:SystemData},
	//Live Location Map 
	{ path: "/nearVehicle", component: NearVehicle},
	{ path: "/liveVehicle", component: LiveVehicle},
	{ path: "/vehicleLocation", component: vehicleLocation},
	//Live Subscription Map 
	{ path: "/pricing", component: Pricing},
	{ path: "/Kyc", component: Kyc},
	//Notification
	{ path: "/Notification", component: Notification },
	//Transactions
	{ path: "/transaction", component: Transaction },
	{path:"/subscriberUser", component:SubscriberUser},
	{path:"/unsubcriberUser", component:UnsubcriberUser},
	{path:"/todayUser", component:TodayUser},
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];
const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/otpVerification", component: OtpVerification },

];
export { authProtectedRoutes, publicRoutes };
