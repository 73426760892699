import React, { useState,useEffect } from 'react';
import { Container } from "reactstrap";
//Import Breadcrumb
import _ from 'lodash'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import List from './userComponents/list';
import { getUserBusinessList } from "../../services/api/api-service";
const MtUserList = (props) => {
    const [userListData, setUserListData] = useState([]);
    useEffect(() => {
        getUserBusinessList("MTSUBSCRIBE").then(data => {
            if (!_.isEmpty(data)) {
                setUserListData(data);
                console.log(data);
            }
        })
    }, [])
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Regester User" breadcrumbItem="User List" />
                        <List title={'MoversTrip User List'} listData={userListData} userlists={setUserListData} userFilterType="MTSUBSCRIBE"/>
                    </Container>
                </div>
            </React.Fragment>


        </>
    );
}
export default MtUserList;