import React, { Component } from 'react';
import { Row, Col,Card,CardBody } from "reactstrap";
import { Link } from "react-router-dom";
class SuccessfullySubmit extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.Successfully.map((project, key) =>
                        <Row className="justify-content-center mt-lg-5" key={"_Successfully_" + key}>
                        <Col xl="5" sm="8">
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col lg="10">
                    <h4 className="mt-4 font-weight-semibold">{project.title}</h4>
                                                <p className="text-muted mt-3">{project.Subtitle}</p>

                                                <div className="mt-4">
                                                    {/* button triggers modal */}
                                                    <Link to="/dashboard" className="btn btn-primary" >
                                                        Back To Dashboard
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-5 mb-2">
                                            <Col sm="6" xs="8">
                                                <div>
                                                    <img src={project.Image} alt="" className="img-fluid"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                  
                                </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    )
                }
            </React.Fragment>
            )
    }
}
export default SuccessfullySubmit;