import React, {useEffect, useState } from 'react';
import {AvField } from "availity-reactstrap-validation";
import {getSystemListType} from '../../services/api/api-service';
const LoadType =(props)=> {
    const [loadTypeValue, setLoadTypeValue] = useState(null);
    const [loadTypeList, setLoadTypeList] = useState([]);
    useEffect(() => {
        getSystemListType('loadsType').then(result => {
            setLoadTypeList(result)
        })
    },[])
        return (
            <React.Fragment>
           <AvField type="select" name="loadsType" label="Load Type" onChange={props.handlerfilter} value={loadTypeValue} >
           <option value="0">--Select--</option>
               {loadTypeList.map((project, key) =>
                         <option  value={project.value}>{project.label}</option> 
                 )}
                     </AvField>
            </React.Fragment>
        );
}

export default LoadType;