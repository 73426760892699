import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, CardSubtitle, Modal } from "reactstrap"
import { deleteBusinessProfile } from '../../../services/api/api-service';
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from "react-bootstrap-sweetalert";
import BusinessProfileModel from "./BusinessProfileModal";
import ToolkitProvider,{ Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import "./datatables.scss"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const BusinessDataTable = (props) => {
    const { ExportCSVButton } = CSVExport;
    let inStockDateFilter;
    //end pagin
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState(false);
    const [mtBusinessId, setMtBusinessId] = useState(null);
    const [userDetails, setUserDetails] = useState([]);
    const togglemodal = () => {
        setModal(false)
    }
    const togglemodal2 = () => {
        setModal2(false)
    }
    const deleteUser = () => {
        // alert(userid);
        setMtBusinessId(userDetails.id);
        setDeleteMsg(true);
    }
    const handlerDelete = () => {
        deleteBusinessProfile(mtBusinessId).then(r => {
            window.location.reload()
        })
    }
    const activateUser = () => {
        setModal(true);
    }
    const columns = [
        {
            dataField: 'companyName',
            text: 'Company Name',
            sort: true,
        }, {
            dataField: 'ownerPhone',
            text: 'Owner Number',
            sort: true
        }, {
            dataField: 'businessTypeTag',
            text: 'Business Type'
        }, {
            dataField: 'city',
            text: 'City',
            sort: true
        }, {
            dataField: 'state',
            text: 'State',
            sort: true
        }, {
            dataField: 'specialServices',
            text: 'Special Services',
            sort: true
        }, {
            dataField: "Active",
            text: "Active",
            sort: false,
            formatter: rankFormatter,
            headerAttrs: { width: 50 },
            attrs: { width: 50, class: "EditRow" }
        }
    ];
    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    lineHeight: "normal"
                }}>

                <Button
                    color="primary"
                    id="edittooltip2"
                    className="btn-sm btn btn-info"
                    onClick={() => handleClick(cell, row, rowIndex)}
                >
                    <i className="mdi mdi-eye font-size-18"></i>

                </Button>
            </div>
        );
    }
    const handleClick = (cell, row, rowIndex) => {
        setUserDetails(row);
        setModal2(true)
    }
    // Table Data
    const productData = props.listData

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }
    const { SearchBar } = Search;
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        },
        {
            text: '20', value: 20
        },
        {
            text: '40', value: 40
        },
        {
            text: '80', value: 80
        },
        {
            text: '100', value: 100
        },
        {
            text: 'All', value: productData.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
        <React.Fragment>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">{props.title}</CardTitle>
                            <CardSubtitle>Find Register business profile</CardSubtitle>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='id'
                                        columns={columns}
                                        data={productData}
                                        search
                                        exportCSV
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
                                                            <hr></hr>
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                bordered={false}
                                                                pagination={paginationFactory(options)}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                filter={filterFactory()}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {deleteMsg ? (
                <SweetAlert
                    title="Do You Delete"
                    success
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={handlerDelete}
                    onCancel={() => setDeleteMsg(false)}
                >
                    You clicked the button!
                </SweetAlert>
            ) : null}
            <BusinessProfileModel togglemodal={togglemodal} modal={modal} userDetails={userDetails} />


            <Modal isOpen={modal2} size="sm" toggle={togglemodal2} centered>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Action</h5>
                    <button
                        type="button"
                        onClick={togglemodal2}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-grid gap-2">
                        {userDetails &&
                            <>
                                <Button
                                    onClick={() => activateUser()}
                                    id="edittooltip2"
                                    style={{ width: '100%' }}
                                    className="btn btn-info  btn btn-info mb-1"
                                > Profile</Button>
                                <Link
                                    to={`/UpdateBusinessList?businessId=${userDetails.id}`}
                                    id="edittooltip2"
                                    style={{ width: '100%' }}
                                    className="btn btn-secondary  btn btn-secondary mb-1"
                                >Edit
                                </Link>
                                <Button
                                    onClick={() => deleteUser()}
                                    id="edittooltip2"
                                    style={{ width: '100%' }}
                                    className="btn btn-danger  btn btn-danger mb-1"
                                > Delete</Button>
                            </>
                        }

                    </div>
                </div>

            </Modal>
        </React.Fragment>
    )
}


export default BusinessDataTable
