import React, {useEffect, useState } from 'react';
import {AvField } from "availity-reactstrap-validation";
import {getSystemListType} from '../../services/api/api-service';
const GoodsType=(props)=> {
    const [goodsTypeValue, setGoodsTypeValue] = useState(null);
    const [goodsTypeList, setGoodsTypeList] = useState([]);
    useEffect(() => {
        getSystemListType('goodsType').then(result => {
            setGoodsTypeList(result)
        })
    },[])
        return (
            <React.Fragment>
            <AvField type="select" name="goodsType" onChange={props.handlerfilter} value={goodsTypeValue} label="Goods Type" >
            <option value="0">--Select--</option>
               {goodsTypeList.map((project, key) =>
                         <option  value={project.value}>{project.label}</option> 
                 )}
            </AvField>
            </React.Fragment>
        );
}

export default GoodsType;