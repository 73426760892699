import React, { Component } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button, CardTitle,Container} from "reactstrap";
import { AvForm, } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadForm from "../Components/LoadForm";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class PostLoad extends Component {
  constructor(props) {
		super(props);
		this.state = {
			success_msg: false,
		};
	}
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Update Profile" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Post Vehicle Load</CardTitle>
                    <AvForm>
                      <LoadForm />
                      <FormGroup className="mb-0 mt-4 ">
                        <div>
                          <Button type="submit" color="primary" onClick={() => this.setState({ success_msg: true })} className="mr-1">
                            Submit
                        </Button>
                        {this.state.success_msg ? (
											<SweetAlert
												title="Do You Submit"
												success
												showCancel
												confirmBtnBsStyle="success"
												cancelBtnBsStyle="danger"
												onConfirm={() => this.setState({ success_msg: false })}
												onCancel={() => this.setState({ success_msg: false })}
											>
												You clicked the button!
											</SweetAlert>
										) : null}
                        </div>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}
export default PostLoad;
