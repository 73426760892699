import React, { useState } from 'react'
import { Link } from "react-router-dom";
const Pagination = ({ postsPerPage, totalPosts, paginate}) => {
    const pageNumbers = [];
    const [currentPage,setCurrentPage]=useState(1)
    if(currentPage===1)
    {
        let pagingEnd=Math.ceil(totalPosts / postsPerPage)>currentPage+5?currentPage+5:Math.ceil(totalPosts / postsPerPage);
        for (let i = 1; i <= pagingEnd; i++) {
            pageNumbers.push(i);
          }
    }
    else
    {
        let startValue=currentPage-5;
        let endValue=currentPage+5;
        let pagingEnd=endValue<=Math.ceil(totalPosts / postsPerPage)?endValue:Math.ceil(totalPosts / postsPerPage);
        for (let i = startValue<1?1:startValue; i <= pagingEnd; i++) {
            pageNumbers.push(i);
        }
    }
    return (
        <nav aria-label="..." style={{ float: 'right' }}>
            <ul className="pagination">
                <li className={currentPage===1?"page-item disabled":"page-item"}>
                    <Link className="page-link" onClick={() => {paginate(currentPage-1);setCurrentPage(currentPage-1)}}>Previous</Link>
                </li>
                {pageNumbers.map((number,key) => (
                    <li key={"_paging_"+key} className={number===currentPage?"page-item active":"page-item"}>
                        <Link className="page-link" onClick={() => {paginate(number);setCurrentPage(number)}} to="#">{number}</Link>
                    </li>
                )
                )}
                <li className={currentPage===pageNumbers.length?"page-item disabled":"page-item"}>
                    <Link className="page-link" onClick={() => {paginate(currentPage+1);setCurrentPage(currentPage+1)}} to="#">Next</Link>
                </li>
            </ul>
        </nav>

    );
}
export default Pagination;