import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, FormGroup, CardTitle, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import _ from 'lodash'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { todayUserList} from '../../services/api/api-service';
//Import Image
import List from './ListComponent/list';
const TodayUser = (props) => {
    const [businessList, setbusinessList] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    useEffect(() => {
        todayUserList().then(data => {
            if (!_.isEmpty(data)) {
                setbusinessList(data)
                //console.log(data);
            }
        })
    }, [stateValue])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Listing" breadcrumbItem="Today User List" />
                    <List title={'Today User list'} listData={businessList} userlists={setbusinessList} userFilterType="MTSUBSCRIBE"/>                 
                </Container>
            </div>
        </React.Fragment>
    );
}
export default TodayUser;