import React, { useState } from 'react';
import { Row, Col, Card, Button, CardBody, CardHeader,CardTitle, Table, Badge, Container } from "reactstrap";
import Pagination from '../Components/pagination';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import MtModelBox from '../Components/MtModelBox';
import MtVehicleDetails from '../Components/MtVehicleDetails';
import { Link } from "react-router-dom";
import {getSystemList, UpdateVehicleDetails } from '../../services/api/api-service';
const MtVehicleList = (props) => {
    const [gpsOptionValue, setGpsOptionValue] = useState(null);
    const [gpsDeviceId, setGpsDeviceId] = useState(null)
    const [gpsOption, setGpsOption] = useState([])
    const gpsDevice = () => {
        getSystemList("Device Provider").then(data => {
            setGpsOption(data)
        })
    }
    const [vehicleDetails, setVehicleDetails] = useState([])
    //pagination start
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const dataList = props.vehicleList.slice(indexOfFirstPost, indexOfLastPost);
    const [modelValue, setmodelValue] = useState(false);
    const [modelLinkGpsValue, setmodelLinkGpsValue] = useState(false);
    //pagination end
    const [successStatus, setSuccessStatus] = useState(true);
    const [vehicleIdValue, setVehicleIdValue] = useState(null);
    const viewVehicleDetails = (list) => {
        setmodelValue(true);
        setVehicleDetails(list);
    }
    const buttonGpsHandler = (vehicleId) => {
        setVehicleIdValue(vehicleId);
        gpsDevice();
        setmodelLinkGpsValue(true);
    }
    const handleSubmit = () => {
        const dataValue = [];
        dataValue.push({
            deviceId: gpsDeviceId,
            deviceProvider: gpsOptionValue
        })
        console.log(dataValue[0]);
        console.log(vehicleIdValue)
        UpdateVehicleDetails(vehicleIdValue, dataValue[0]).then(data => {
            console.log(data);
            setSuccessStatus(false)
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Vehicle" breadcrumbItem="User Vehicle List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>User Vehicle List</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {props.vehicleList.length} entries</div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <Table className="table table-centered table-nowrap">
                                            <thead className="thead-light">
                                                <th>Vehicle No.</th>
                                                <th>Type</th>
                                                <th>Reg.Date</th>
                                                <th></th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataList.map((List, key) =>
                                                        <tr key={"_order_" + key}>

                                                            <td>{List.vehicleNumber}</td>
                                                            <td>{List.vehicleType}</td>
                                                            <td>
                                                                {new Date(List.createdAt).toLocaleString()}
                                                            </td>
                                                            <td>
                                                                <Badge className={List.status === "Unverified" ? "font-size-12 badge-soft-danger" : "font-size-12 badge-soft-success"} color={List.status === "Unverified" ? "denger" : "success"} pill>{List.status}</Badge>
                                                            </td>
                                                            <td>
                                                                <Link className="mr-2 action-icon text-success"
                                                                    onClick={() => viewVehicleDetails(List)}>
                                                                    <i className="mdi mdi-eye font-size-18"></i>
                                                  
                                                </Link>
                                                                <Link className={`mr-2 action-icon text-info ${List.deviceId?"invisible":""}`}
                                                                    onClick={() => buttonGpsHandler(List.id)}>
                                                                    <i className="mdi mdi-link font-size-18"></i>
                                                  
                                                </Link>
                                                             
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination
                                                postsPerPage={postsPerPage}
                                                totalPosts={props.vehicleList.length}
                                                paginate={paginate} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {modelValue &&
                    <MtModelBox sizeValue="xl" titleLabel="Vehicle Detials" modelValue={modelValue}  modelSetValue={setmodelValue}>
                        <MtVehicleDetails jsonData={vehicleDetails}></MtVehicleDetails>
                    </MtModelBox>
                }
                {modelLinkGpsValue &&
                    <MtModelBox sizeValue="lg" titleLabel="Link To Gps" modelValue={modelLinkGpsValue} modelSetValue={setmodelLinkGpsValue}>
                        {successStatus &&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Device Id</label>
                                    <div className="col-md-10">
                                        <input className="form-control" onChange={e => setGpsDeviceId(e.target.value)} name="deviceId" type="text" placeholder="Device Id" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label">Device Provider</label>
                                    <div className="col-md-10">
                                        <select className="form-control" onChange={e => setGpsOptionValue(e.target.value)} id="deviceProvider">
                                            <option>Select</option>
                                            {gpsOption.map((list, key) =>
                                                <option value={list.value}>{list.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <Button type="submit" onClick={handleSubmit} className="btn btn-success float-right">Submit</Button>
                            </>
                        }
                        {!successStatus &&
                        <>
                            <div class="mt-0 card-title">Successfully link GPS</div>
                        </>
                        }
                    </MtModelBox>
                }
            </div>
        </React.Fragment>
    );
}

export default MtVehicleList;