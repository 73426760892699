import React, { useState } from 'react';
import {AvField } from "availity-reactstrap-validation";
const State =(props)=>{
   const [stateList,setStatelist]=useState(props.stateList);
        return (
            <React.Fragment>
                <AvField type="select" name="State" label="State" helpMessage="Select your state">  
                  {stateList}       
                </AvField>
            </React.Fragment>
        );
    
}

export default State;