import React, { useState } from 'react';
import {AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash'
import {resetPasswordPanel} from '../../services/api/api-service'
const ResetPassword =(props)=>{
   const [conformMsg,setConformMsg]=useState(true);
   const [successMsg,setSuccessMsg]=useState(false);
   const [CurrentPassword,setCurrentPassword]=useState(null);
   const handleResetPassword =()=>{
    resetPasswordPanel(props.userid,true).then(data=>{
        if (!_.isEmpty(data)) {
            console.log(data)
            setCurrentPassword(data.response)
            setConformMsg(false)
            setSuccessMsg(true)
        }
    })
   }
   const handleSuccess =()=>{
    setSuccessMsg(false)
   }
        return (
            <React.Fragment>
                {conformMsg? (
											<SweetAlert
												title="Reset User Password"
												confirmBtnText="Reset Password"
												onConfirm={handleResetPassword}
											>
												Do You Reset User Password
											</SweetAlert>
										) : null}

										{successMsg ? (
											<SweetAlert
												confirmBtnText="Ok"
												title="User Password Successfully Reset"
												onConfirm={handleSuccess}
											>
												{CurrentPassword}
											</SweetAlert>
										) : null}
            </React.Fragment>
        );
    
}

export default ResetPassword;