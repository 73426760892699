import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash'
const MtSweetAlert = (props) => {
    return (
        <React.Fragment>
            <SweetAlert
                title={props.alertTitle}
                confirmBtnText={props.alertConfirmBtnText}
                onConfirm={props.alertHandler}
            >
               {props.children}
											</SweetAlert>
        </React.Fragment>
    );

}

export default MtSweetAlert;