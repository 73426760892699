import React, { Component } from 'react';
import { Row, Col, Card, CardBody,CardTitle,Table,TabContent, TabPane, NavItem, NavLink,Form,Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SuccessfullySubmit from "../Components/SuccessfullySubmit";
import SweetAlert from "react-bootstrap-sweetalert";
import State from "../Components/State";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import Img from '../../assets/images/Successfullyregister.png';
const dropzoneheight = {
    minHeight: '100px',
    border: '2px dashed #000000',
    background: '#fff',
    borderRadius:'6px',
  };
class KycForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeTabProgress: 1,
      success_msg: false,
      progressValue : 25,
      selectedFiles: [],
      selectedFiles1: [],
      selectedFiles2: [],
      Successfully: [
        {
            title: "Successfully Submit", Subtitle: "Member Kyc Is Successfully Submit ",Image:Img,
        },
    ]
    };
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.handleAcceptedFiles1 = this.handleAcceptedFiles1.bind(this);
    this.handleAcceptedFiles2 = this.handleAcceptedFiles2.bind(this);
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );
    this.setState({ selectedFiles: files });
  };
  handleAcceptedFiles1 = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );
    this.setState({ selectedFiles1: files });
  };
  handleAcceptedFiles2 = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );
    this.setState({ selectedFiles2: files });
  };
   /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if(tab >= 1 && tab <=4 ){
                this.setState({
                    activeTab: tab
                });
            }
        }
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
        if(tab >= 1 && tab <=4 ){
            this.setState({
              activeTabProgress: tab
            });

          if(tab === 1) { this.setState({progressValue : 25}) }
          if(tab === 2) { this.setState({progressValue : 50}) }
          if(tab === 3) { this.setState({progressValue : 75}) }
          if(tab === 4) { this.setState({progressValue : 100}) }
        }
    }
}
ShowSuccessfullyBox = () => {
  document.getElementById("successfullySubmitBox").style.display = "block";
  document.getElementById("FormView").style.display = "none";
}
  render() {
        return (
          <React.Fragment>
              {
                 this.props.FormType.map((FormTypeView, key) =>
                  <div id="kycForm" key={"_FormTypeView_" + key} > 
                     <div id="FormView">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Member Kyc</h4>
                                        <div id="progrss-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                            <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 1 })} onClick={() => { this.toggleTabProgress(1); }} >
                                                                        <span className="step-number mr-2">01</span>
                                                                        User Detail
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 2 })} onClick={() => { this.toggleTabProgress(2); }} >
                                                                        <span className="step-number mr-2">02</span>
                                                                        <span>Company Document</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 3 })} onClick={() => { this.toggleTabProgress(3); }} >
                                                                        <span className="step-number mr-2">03</span>
                                                                        KYC Preview
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 4 })} onClick={() => { this.toggleTabProgress(4); }} >
                                                                    <span className="step-number mr-2">04</span>
                                                                    Confirm Detail
                                                                    </NavLink>
                                                                </NavItem>
                                            </ul>

                                            <div id="bar" className="mt-4">
                                                <Progress color="success" striped animated value={this.state.progressValue} />
                                                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                                            </div>
                                            <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                <AvForm>
                                                   <Row>
                    <Col md="12">
                      <AvField
                        name="fullName"
                        label="Full Name"
                        placeholder="Enter Full Name"
                        type="text"
                        errorMessage="Please enter full name"
                        helpMessage="Full name same on document"
                        validate={{ required: { value: true } }}
                      />
                      </Col>
                      <Col md="4">
                      <State />
                      </Col>
                      <Col md="4">
                      <AvField
                        name="City"
                        label="City"
                        placeholder="Enter City"
                        type="text"
                        errorMessage="Please enter your city name"
                        validate={{ required: { value: true } }}
                      />
                      </Col>
                      <Col md="4">
                      <AvField
                        name="pinCode"
                        label="Pin Code"
                        placeholder="Enter area pin code"
                        type="text"
                        errorMessage="Enter area pin code"
                        validate={{ required: { value: true } }}
                      />
                      </Col>
                        </Row>
                                                   </AvForm>
                                  
                                                </TabPane>
                                                <TabPane tabId={2}>

                                                <div>
                                                  <AvForm>
<Row>
<Col md="6">
<AvField
name="panNumber"
label="PAN Number"
placeholder="Enter pan number"
type="text"
errorMessage="Enter pan number"
validate={{ required: { value: true } }}
/>
</Col> 
<Col md="6">
<AvField
name="aadharCard"
label="Aadhar Card Number"
placeholder="Enter aadhar card number"
type="text"
errorMessage="Enter aadhar card number"
validate={{ required: { value: true } }}
/>
</Col>   
</Row>
</AvForm>
                                                    <Form>  
                                                      <h4>Upload Document</h4>
                                                      <label>Pan Card <strong className="text-danger">(Upload File Format Must be exp: .JPG, .JPEG, .PNG)*</strong></label>
                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div style={dropzoneheight}>
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                   
                            {/* start Aadhar Card */}
                      <label>Aadhar Card<strong className="text-danger">(Upload File Format Must be exp: .JPG, .JPEG, .PNG)*</strong></label>
                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles1(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div style={dropzoneheight}>
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles1.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      {/* End Aadhar Card File */}

                                     {/* start  Profile Image    */}
                                     <label>Profile Image<strong className="text-danger">(Upload File Format Must be exp: .JPG, .JPEG, .PNG)*</strong></label>
                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles2(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div style={dropzoneheight}>
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles2.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                        {/* End Profile Image  */}
                     </Form>
                     </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                <div>
                                                    <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 text-success">Member Kyc Detail</CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table table-sm m-0 table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Full Name:</th>
                                                        <td colSpan="5">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">State:</th>
                                                        <td>Value</td>
                                                        <th scope="row">City:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Pin Code:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <CardTitle className="mb-4 mt-4 text-success">Document Detail</CardTitle>

                                        <div className="table-responsive">
                                        <Table className="table table-sm m-0 table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Pin Card Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Aadhar Card Number:</th>
                                                        <td>Value</td>
                                                      
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                                   </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li className={this.state.activeTabProgress === 1 ? "previous d-none" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1);} }>Previous</Link></li>
                                                <li className={this.state.activeTabProgress === 4 ? "next mr-2" : "next mr-2 d-none"}><Link to="#" className={this.state.activeTabProgress === 4 ? FormTypeView.Type==="New" ? "visible" : "d-none" :"d-none"} onClick={() => this.setState({ success_msg_Save: true })} >Save</Link>
                                                {this.state.success_msg_Save ? (
											<SweetAlert
												title="Do You Submit"
												success
												showCancel
												confirmBtnBsStyle="success"
												cancelBtnBsStyle="danger"
												onConfirm={this.ShowSuccessfullyBox}
												onCancel={() => this.setState({ success_msg: false })}
											>
												You clicked the button!
											</SweetAlert>
										) : null}
                                                </li>
                                                <li className={this.state.activeTabProgress === 4 ? "next mr-2" : "next mr-2 d-none"}><Link to="#" className={this.state.activeTabProgress === 4 ? FormTypeView.Type==="Edit" ? "visible" : "d-none" :"d-none"} onClick={() => this.setState({ success_msg_Update: true })}  >Edit</Link>
                                                {this.state.success_msg_Update ? (
											<SweetAlert
												title="Do You Submit"
												success
												showCancel
												confirmBtnBsStyle="success"
												cancelBtnBsStyle="danger"
												onConfirm={this.ShowSuccessfullyBox}
												onCancel={() => this.setState({ success_msg: false })}
											>
												You clicked the button!
											</SweetAlert>
										) : null}
                                                </li>
                                                <li className={this.state.activeTabProgress === 4 ? "next disabled mr-2 d-none" : "next mr-2"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress + 1);} }>Next</Link></li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        
                        </div>
                        <div id="successfullySubmitBox" style={{display:"none"}}>
            <SuccessfullySubmit Successfully={this.state.Successfully} />
            </div>
         
            </div>
                 )
              }
            </React.Fragment>
        )
    }
}
export default KycForm;