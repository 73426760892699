import React, { Component } from "react";
import { Row, Col,Input} from "reactstrap";
import {AvField } from "availity-reactstrap-validation";
import VehicleType from "./VehicleType";
import GoodsType from "./GoodsType";
import LoadType from "./LoadType";
class LoadForm extends Component {
    render() {
        return (
            <React.Fragment>
             <Row>
                     <Col md="6">
                      <VehicleType/>
                      </Col>
                      <Col md="6">
                      <GoodsType/>
                       </Col>
                       <Col md="6">
                     <LoadType/>
                       </Col>
                     
                            <Col md="6">
                      <AvField
                        name="contactName"
                        label="Contact Name"
                        placeholder="Enter contact name"
                        type="text"
                        errorMessage="Enter contact name"
                        validate={{ required: { value: true } }}
                      />
                      </Col>
                      <Col md="6">
                       <AvField
                        name="contactNo"
                        label="Contact Number"
                        placeholder="Enter contact number"
                        type="number"
                        errorMessage="Enter contact number"
                        validate={{ required: { value: true } }}
                      /></Col>
                     
                            <Col md="6">
                            <AvField
                        name="pickupLocation"
                        label="Pickup Location"
                        placeholder="Enter pickup location"
                        type="text"
                        errorMessage="Enter pickup location"
                        validate={{ required: { value: true } }}
                      />
                            </Col>
                            <Col md="6">
                            <AvField
                        name="destination"
                        label="Destination"
                        placeholder="Enter destination"
                        type="text"
                        errorMessage="Enter destination"
                        validate={{ required: { value: true } }}
                      />
                            </Col>
                            <Col md="6">
                            <AvField
                        name="itemWeight"
                        label="Weight"
                        placeholder="Enter weight"
                        type="text"
                        errorMessage="Enter weight"
                        validate={{ required: { value: true } }}
                      />
                            </Col>
                            <Col md="12">
                            
                       <Input
                              type="textarea"
                              id="specialInstruction"
                              name="specialInstruction"
                              className="form-control"
                              placeholder="Enter special instruction"
                              rows="3"
                            />
                            </Col>
                        </Row>
            </React.Fragment>
        );
    }
}   
export default LoadForm;