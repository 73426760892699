import React, { Component } from 'react';
import { Container, Row, Col, Table,Card, CardBody, FormGroup,CardTitle, Button,} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class SearchVehicleDetails extends Component {
    ShowVehicleDetails = () => {
        document.getElementById("vehicleDetailsBox").style.display = "block";
      }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Vehicle" breadcrumbItem="Find Vehicle Detail" />
                        <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <AvForm>
                    <Row>
                       <Col md="6">
                       <AvField
                        name="searchText"
                        helpMessage="Exp:RJ01GS1212, rjo1gs1212"
                        label="Find Vehicle Detail"
                        placeholder="Search..."
                        type="Search"
                      /></Col>
                       <Col md="2">
                       <FormGroup className="mb-0 mt-4 ">
                        <div>
                          <Button type="submit" color="primary" onClick={this.ShowVehicleDetails} className="mr-1">
                            Find Vehicle
                        </Button>{" "}
                        </div>
                      </FormGroup>
                           </Col>
                        </Row>
                     
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
                       <Row id="vehicleDetailsBox" style={{display: "none"}}>
                           <Col md="12">
                           <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 text-success">Vehicle Details</CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table table-sm m-0 table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Owner Name:</th>
                                                        <td colSpan="5">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Vehicle Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Vehicle Type:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Model No:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Chassis Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Engine No:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Load Capacity:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                    
                                                     <tr>
                                                        <th scope="row">RC Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">PAN Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Vehicle Registration Date:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <CardTitle className="mb-4 mt-4 text-success">Document Detail</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">PAN Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">RC Number:</th>
                                                        <td>Value</td>
                                                        <th scope="row">Vehicle Registration Date:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Nation Permit Number:</th>
                                                        <td>Value</td>
                                                   
                                                        <th scope="row">Insurance Number:</th>
                                                        <td>Value</td>
                                                   
                                                        <th scope="row">Fitness Number:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        </CardBody>
                                        </Card>
                           </Col>
                       </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SearchVehicleDetails ;