import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, FormGroup, CardTitle, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Cards
import _ from 'lodash'
import NeedLoad from "../Components/NeedLoad";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { findNeedLoad, getSystemList, getNeedLoadList } from '../../services/api/api-service';
import Pagination from '../Components/pagination';
const NeedLoadList = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [needLoadListData, setNeedLoadListData] = useState([]);
    const [cityList, setCityList] = useState([]);
    const handleValidSubmit = (event, values) => {
        findNeedLoad(values.searchText).then(data => {
            if (!_.isEmpty(data)) {
                data.fromCityName = _.get(_.find(cityList, { value: data.fromLocation }), "label", data.fromLocation)
                setNeedLoadListData(data);
            }
        })
    }
    const fetchPostLoadList = (citylist) => {
        getNeedLoadList("All").then(result => {
            result.map(r => {
                r.fromCityName = _.get(_.find(citylist, { value: r.fromLocation }), "label", r.fromLocation)
                return r;
            })
            setNeedLoadListData(result)
        })
    }
    useEffect(() => {
        getSystemList('city').then(citylist => {
            if (!_.isEmpty(citylist)) {
                setCityList(citylist);
                fetchPostLoadList(citylist);
            }

        }).catch(e => {
            console.log("Error in State list:", e);
        })

    }, [])
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = needLoadListData.slice(indexOfFirstPost, indexOfLastPost);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Vehicle" breadcrumbItem="NEED LOAD LIST" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Search By Filter</CardTitle>
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row>

                                            <Col md="6">
                                                <AvField
                                                    name="searchText"
                                                    label="Search"
                                                    placeholder="Search..."
                                                    type="searchText"
                                                    helpMessage="Search By Number And Name"
                                                /></Col>
                                            <Col md="2">
                                                <FormGroup className="mb-0 mt-4 ">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1">
                                                            Search
                                                        </Button>

                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {currentPosts.length} entries</div>
                        </Col>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Vehicle Number</th>
                                                <th scope="col">Item Weight</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Destination</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Contact</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <NeedLoad projects={needLoadListData} />
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={needLoadListData.length}
                                paginate={paginate} />
                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
}
export default NeedLoadList;