import React, { useState } from 'react';
import { FormGroup } from "reactstrap";
import { uploadFile } from '../../services/api/api-service';
const UploadFile = (props) => {
    //no file selected Default
    const [imagePath, setImagePath] = useState(props.currentImage);
    //console.log("Current Image Path:-"+props.currentImage)
    const [selectedFile, setselectedFile] = useState();
    const [errorValue, setErrorValue] = useState(null);
    const [uploadSuccessfuly, setUploadSuccessfuly] = useState(null);
    const [fileStatus, setFileStatus] = useState(false);
    // On file select (from the pop up) 
    const onFileChange = (event) => {
        setErrorValue(null);
        setFileStatus(false)
        let reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            setImagePath(reader.result);
        };
        setselectedFile(event.target.files[0]);
        if (!event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) { setErrorValue("Image Extension No Valid") }
        else {
            event.target.files[0].size >= 5120 && event.target.files[0].size <= 71680 ? (
                setFileStatus(true)
            ) :
                (
                    setErrorValue("Image Min Six 5 KB And Max Size 70 KB")
                )
        }
    };
    const onFileUpload = () => {
        console.log(selectedFile);
        if (selectedFile) {
            if (fileStatus) {
                uploadFile(selectedFile, props.bucketName, props.UploadPath).then(data => {
                    props.fileValue(data.data.result.files.myFile[0].providerResponse.location);
                   setUploadSuccessfuly("File Upload Successfully");
                }).catch(e => {
                    console.log("Error in File Upload:", e);
                })
            } else {
                setErrorValue("File  Not Valid");
            }

        }
        else {
            setErrorValue("File Not select")
        }

    }
    return (
        <React.Fragment>
            <FormGroup>
                <label htmlFor="validationCustom02">{props.labelValue}</label>
                <div className="row">
                    <div className="col-7">
                        <input type="file" onChange={onFileChange} />
                    </div>
                    <div className="col-1">
                        <button type='button' className="btn btn-dark waves-effect waves-light btn btn-dark" onClick={onFileUpload}> Upload </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {fileStatus &&
                            <img src={imagePath} alt={"_Image"} height={150} width={200} />}
                    </div>
                    <div className="col-12">
                        <label style={{ color: 'red' }}>{errorValue}</label>
                        <label style={{ color: 'green' }}>{uploadSuccessfuly}</label>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    );

}

export default UploadFile;