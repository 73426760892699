import React, { useEffect, useState } from 'react';
import {Row, Col, FormGroup, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { getSystemList, getSystemCity, createBusinessListing, findRegisterBusiness } from '../../services/api/api-service';
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import UploadFile from '../Components/uploadFile';
import MtSweetAlert from '../Components/MtSweetAlert';
import MtFormLayout from '../Components/MtFormLayout';
import _ from 'lodash'
const AddBusinessProfile = (props) => {
    const query = new URLSearchParams(_.get(props, "location") ? props.location.search : null);
    const token = query.get('mtUserId')
    console.log(token + " mtuserid");
    let history = useHistory();
    const [transporterType, setTransporterType] = useState(null);
    const [transporterTypeList, setTransporterTypeList] = useState([]);

    //alert 
    const [conformMsg, setConformMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState("Something Wrong !");
    //end-alert
    //Add Post Data 
    const [uploadData, setUploadData] = useState([]);
    const [listingCategory, setlistingCategory] = useState(null);
    const [listingCategoryList, setListingCategoryList] = useState([]);
    const [stateValue, setStateValue] = useState();
    const [stateList, setStatelist] = useState([]);
    const [cityValue, setCityValue] = useState(null);
    const [cityList, setCitylist] = useState([]);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [businessCardValue, setBusinessCardValue] = useState(null);
    const [serviceType, setServiceType] = useState(null);
    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [mobileStatus, setMobileStatus] = useState(false);
    const [businessId, setBusinessId] = useState(null);
    const goBack = () => {
        history.goBack()
        setSubmitStatus(false);
    }
    const handleValidSubmit = (event, values, actions) => {
        if (stateValue == null) {
            setSubmitStatus(true)
            setAlertMsg("State Not Selected !");
        }
        else if (cityValue == null) {
            setSubmitStatus(true)
            setAlertMsg("City Not Selected !");
        }
        else if (serviceType == null) {
            setSubmitStatus(true)
            setAlertMsg("Special Services Type!");
        }
        else {
            const valueSet = {
                state: stateValue.value,
                city: cityValue.value
            }
            const mtBusinessType = [];
            if (listingCategory == null) {
                setSubmitStatus(true)
                setAlertMsg("Business Type Not Selected !");
            }
            else {
                if (listingCategory.value === "Packers And Movers,Transporter") {
                    mtBusinessType[0] = "Packers And Movers";
                    mtBusinessType[1] = "Transporter";
                }
                else {
                    mtBusinessType[0] = `${listingCategory.value}`;
                }
                findRegisterBusiness(values.contactNumber,cityValue.value).then(data => {
                    //console.log(data);
                    if (!_.isEmpty(data)) {
                    setBusinessId(data[0].id);
                    setMobileStatus(true);
                }
                })
            }
            const dataValue = [];
            dataValue.push({
                ...valueSet,
                mtUserId: token,
                companyName: values.companyName,
                contactPerson: values.contactPerson,
                address: values.address,
                businessEmail: values.businessEmail,
                website: values.website,
                businessTypeTag: mtBusinessType,
                pin: values.pin,
                ownerName: values.contactPerson,
                ownerPhone: values.contactNumber,
                contactNumber: values.contactNumber,
                businessCard: businessCardValue,
                routeVehicleLoaded: values.routeVehicleLoaded,
                specialServices: serviceType.value,
                vehicleCategory:transporterType.value
            });
            console.log(dataValue);
            setUploadData(dataValue);
            if (!_.isEmpty(dataValue)) {
                setConformMsg(true);
            }
            else {
                setSubmitStatus(true);
                setAlertMsg("Somthing Wrong!");
            }
        }
    }
    const submitHandler = () => {
        if (mobileStatus) {
            setErrorStatus(true);
            setConformMsg(false)
            setSuccessMsg(false);
        }
        else {
            createBusinessListing(uploadData).then(result => {
                setConformMsg(false)
                setSuccessMsg(true);
            }).catch(alert);
        }

    }
    useEffect(() => {
        getSystemList('listtype').then(setListingCategoryList);
        getSystemList('ServiceType').then(setServiceTypeList);
        getSystemList('TransporterType').then(setTransporterTypeList);
        getSystemList('state').then(data => {
            if (!_.isEmpty(data)) {
                setStatelist(data)
            }
        }).catch(e => {
            console.log("Error in State list:", e);
        })
    }, [])
    useEffect(() => {
        if (stateValue) {
            getSystemCity(stateValue.value).then(data => {
                const allCityData = []
                if (!_.isEmpty(data)) {
                    data.forEach(element => {
                        allCityData.push({ label: element.dbCity, value: element.dbCity })
                    })
                    setCitylist(allCityData)
                }
            }).catch(e => {
                console.log("Error in State list:", e);
            })
        }
    }, [stateValue])
    return (
        <React.Fragment>
            <MtFormLayout
                breadcrumbsTitle="Busines List"
                breadcrumbItem="Add Business Profile"
                title="Add Business Profile"
                subTitle="Fill The Details And Create Business Profile">
                <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} >
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Business Category</Label>
                                <Select
                                    value={listingCategory}
                                    onChange={setlistingCategory}
                                    options={listingCategoryList}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Company Name</Label>
                                <AvField
                                    name="companyName"
                                    placeholder="Company Name"
                                    type="text"
                                    errorMessage="Enter Company Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Contact Person</Label>
                                <AvField
                                    name="contactPerson"
                                    placeholder="Contact Person"
                                    type="text"
                                    errorMessage="Enter Contact Person"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Contact Number</Label>
                                <AvField
                                    name="contactNumber"
                                    placeholder="Contact Number"
                                    type="text"
                                    errorMessage="Enter Contact Number"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Mail Id (Optional)</Label>
                                <AvField
                                    name="businessEmail"
                                    placeholder="Business Email"
                                    type="text"
                                    value="example@gmail.com"
                                    errorMessage="Enter Business Email"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Website Url (Optional)</Label>
                                <AvField
                                    name="website"
                                    placeholder="website"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Address</Label>
                                <AvField
                                    name="address"
                                    placeholder="Address"
                                    type="text"
                                    errorMessage="Enter Address"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">State</Label>
                                <Select
                                    defaultValue={stateValue}
                                    name="state"
                                    value={stateValue}
                                    onChange={setStateValue}
                                    options={stateList}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">City</Label>
                                <Select
                                    name="city"
                                    value={cityValue}
                                    onChange={setCityValue}
                                    options={cityList}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Pin Code</Label>
                                <AvField
                                    name="pin"
                                    placeholder="Pin Code"
                                    type="text"
                                    errorMessage="Enter Pin Code"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Route Vehicle Loaded</Label>
                                <AvField
                                    name="routeVehicleLoaded"
                                    placeholder="Route Vehicle Loaded"
                                    type="text"
                                    errorMessage="Enter Route Vehicle Loaded"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom02"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Special Services</Label>
                                <Select
                                    value={serviceType}
                                    onChange={setServiceType}
                                    options={serviceTypeList}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="validationCustom02">Transporter Type</Label>
                                <Select
                                    value={transporterType}
                                    onChange={setTransporterType}
                                    options={transporterTypeList}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <UploadFile labelValue="Upload Business Card"
                                    bucketName="moverstrip-prod"
                                    fileValue={setBusinessCardValue}
                                    UploadPath="business-cards/" currentImage={null} />
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            {submitStatus &&
                                <div className="alert alert-danger fade show" role="alert">{alertMsg}</div>
                            }
                        </Col>
                    </Row>
                    <Button color="success" type="submit">Submit</Button>
                </AvForm>
            </MtFormLayout>
            {conformMsg ? (
                <MtSweetAlert
                    alertTitle="Add Business Profile"
                    alertConfirmBtnText="Submit"
                    alertHandler={submitHandler}>
                    Do You Submit Data
                </MtSweetAlert>
            ) : null}
            {successMsg ? (
                <MtSweetAlert
                    alertTitle="Business Profile"
                    alertConfirmBtnText="Go Back"
                    alertHandler={goBack} >
                    Business Profile Add Successfully
                </MtSweetAlert>
            ) : null}
            {errorStatus ? (
                <MtSweetAlert
                    alertTitle="Already Register"
                    alertConfirmBtnText="Go Back"
                    alertHandler={goBack} >
                    Business profile is already created
                    <Link
                        to={`/UpdateBusinessList?businessId=${businessId}`}                                     
                        id="editProfile"
                        className="btn btn-info"
                    >Current profile Update
                    </Link>
                </MtSweetAlert>
            ) : null}
        </React.Fragment>
    );
}
export default AddBusinessProfile