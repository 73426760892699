import React, { useState, useEffect } from 'react';
import {Label} from "reactstrap";
import Select from "react-select";
import { getSystemListType } from "../../services/api/api-service"
const SelectList = (props) => {
  const [list, setlist] = useState([]);
  useEffect(() => {
    getSystemListType(props.type).then(result => {
      setlist(result)
    })
  }, [])
  return (
    <React.Fragment>
      <Label htmlFor="validationCustom01">{props.label} <strong style={{color:'red'}}>*</strong></Label>
      <Select
        defaultValue={props.stateValue}
        name={props.name}
        value={props.stateValue}
        onChange={props.setStateValue}
        options={list}
      />
    </React.Fragment>
  );

}

export default SelectList;