import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, FormGroup, CardBody, Table, CardTitle } from "reactstrap";
import MtDownloadLayout from './MtDownloadLayout'
const MtVehicleDetails = (props) => {
  const vehicleDetails = props.jsonData;
  console.log(props.jsonData);
  return (
    <React.Fragment>
      <div className="table-responsive">
        {vehicleDetails &&
          <Table className="table table-sm m-0 table">
            <tbody>
              <tr>
                <th scope="row">Vehicle Number:</th>
                <td>{vehicleDetails.vehicleNumber}</td>
                <th scope="row">Vehicle Type:</th>
                <td>{vehicleDetails.vehicleType}</td>
                <th scope="row">Model No:</th>
                <td>{vehicleDetails.modelno}</td>
              </tr>
              <tr>
                <th scope="row">Chassis Number:</th>
                <td>{vehicleDetails.chassisNumber}</td>
                <th scope="row">Engine No:</th>
                <td>{vehicleDetails.engineNo}</td>
                <th scope="row">Load Capacity:</th>
                <td>{vehicleDetails.loadCapacity}</td>
              </tr>
              <tr>
                <th scope="row">Owner Name:</th>
                <td>{vehicleDetails.ownerName}</td>
                <th scope="row">Owner Contact Number:</th>
                <td colSpan="3">{vehicleDetails.ownerContact}</td>
              </tr>

            </tbody>
          </Table>
        }
      </div>
      <CardTitle className="mb-4 mt-4 text-success">Document Details</CardTitle>

      <div className="table-responsive">
        {vehicleDetails &&
          <Table className="table table-sm m-0 table">
            <tbody>
              <tr>
                <th scope="row">PAN Number:</th>
                <td>{vehicleDetails.panNumber}</td>
                <th scope="row">RC Number:</th>
                <td>{vehicleDetails.rcNumber}</td>
                <th scope="row">Vehicle Registration Date:</th>
                <td>{vehicleDetails.rcDate}</td>
              </tr>
              <tr>
                <th scope="row">Nation Permit Number:</th>
                <td>{vehicleDetails.nationPermitNumber}</td>
                <th scope="row">Nation Permit Date:</th>
                <td>{vehicleDetails.nationPermitRegistrationDate}</td>
                <th scope="row">Nation Permit Expiry Date:</th>
                <td>{vehicleDetails.nationPermitExpiryDate}</td>
              </tr>
              <tr>
                <th scope="row">Insurance Number:</th>
                <td>{vehicleDetails.insuranceNumber}</td>
                <th scope="row">Insurance Start Date:</th>
                <td>{vehicleDetails.insuranceNumber}</td>
                <th scope="row">Insurance Expiry Date:</th>
                <td>{vehicleDetails.insuranceNumber}</td>
              </tr>
              <tr>
                <th scope="row">Fitness Number:</th>
                <td>{vehicleDetails.fitnessNumber}</td>
                <th scope="row">Fitness Start Date:</th>
                <td>{vehicleDetails.fitnessStarteDate}</td>
                <th scope="row">Fitness Expiry Date:</th>
                <td>{vehicleDetails.fitnessExpiryDate}</td>
              </tr>
            </tbody>
          </Table>
        }
      </div>
      <CardTitle className="mb-4 mt-4 text-success">Upload Document</CardTitle>
      <Row>
        <Col lg={4}>
          <MtDownloadLayout imageLabel="Pen Card" imageType="Vehicle Document" imageUrl={vehicleDetails.penCard} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="RC Card" imageType="Vehicle Document" imageUrl={vehicleDetails.rcCard} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="National Permit" imageType="Vehicle Document" imageUrl={vehicleDetails.nationalPermit} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Insurance" imageType="Vehicle Document" imageUrl={vehicleDetails.insurance} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Fitness" imageType="Vehicle Document" imageUrl={vehicleDetails.fitness} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Driving Licence" imageType="Vehicle Document" imageUrl={vehicleDetails.drivingLicence} />
         </Col>
      </Row>


    </React.Fragment>
  );
}

export default MtVehicleDetails;