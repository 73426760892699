import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
//i18n
import { withNamespaces } from 'react-i18next';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }
  render() {
    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown">
            <i className="bx bx-bell bx-tada"></i>
            <span className="badge badge-danger badge-pill">3</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                </Col>
                <div className="col-auto">
                  <a href="/Notification" className="small"> View All</a>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              <Link to="/Notification" className="text-reset notification-item">
                <div className="media">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-cart"></i>
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{this.props.t('Your order is placed')}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{this.props.t('If several languages coalesce the grammar')}</p>
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {this.props.t('3 min ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>

            </SimpleBar>
            <div className="p-2 border-top">
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="/Notification"
              >
                {" "}
               {this.props.t('View all')}{" "}
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
