import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import VehicleType from "../Components/VehicleType";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Container, Row, Col, Button, Card, FormGroup, CardBody, Table, Badge, UncontrolledTooltip } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Pagination from '../Components/pagination';
import MtModelBox from '../Components/MtModelBox';
import MtVehicleDetails from '../Components/MtVehicleDetails';
import _ from 'lodash';
import { getAllVehicle, findVehicle, getVehicleDetailsbyId } from '../../services/api/api-service'
const VehicleList = () => {
    const [model, setModel] = useState(false);
    const [vehicleList, setVehileList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    //pagination start
    const [postsPerPage] = useState(50);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = vehicleList.slice(indexOfFirstPost, indexOfLastPost);
    //pagination end
    //filter
    const [vehicleTypeValue, setVehicleTypeValue] = useState(null);
    const [vehicleStatus, setVehicleStatus] = useState(null);
    //modelVehicle Details
    const [modelValue, setmodelValue] = useState(false);
    const [vehicleDetails, setVehicleDetails] = useState([]);
    const viewVehicleDetails = (vehicleList) => {
        setmodelValue(true);
        setVehicleDetails(vehicleList);
    }
    const handlerStatusfilter = (event, values) => {
        setVehicleStatus(values);
    }
    useEffect(() => {
        getAllVehicle(vehicleTypeValue, vehicleStatus).then(data => {
            if (!_.isEmpty(data)) {
                setVehileList(data)
            }
        }).catch(e => {
            alert(e)
        })
    }, [vehicleTypeValue, vehicleStatus])
    const handleValidSubmit = (event, values) => {
        findVehicle(values.searchText).then(data => {
            setVehileList(data);
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Vehicle" breadcrumbItem="Vehicle List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleValidSubmit} >
                                        <Row>
                                            <Col md="2">
                                                <VehicleType getVehicleType={setVehicleTypeValue} valueSelect={vehicleTypeValue} />
                                            </Col>
                                            <Col md="2">
                                                <AvField type="select" name="verifyUnverifiedSelect" onChange={handlerStatusfilter} label="Select Verify/Unverified" >
                                                    <option>--Select--</option>
                                                    <option>Verified</option>
                                                    <option>Unverified</option>
                                                </AvField>
                                            </Col>
                                            <Col md="6">
                                                <AvField
                                                    name="searchText"
                                                    helpMessage="Exp:RJ01GS1212"
                                                    label="Keyword Search"
                                                    placeholder="Search..."
                                                    type="Search"
                                                    required
                                                />
                                            </Col>
                                            <Col md="2">
                                                <FormGroup className="mb-0 mt-4 ">
                                                    <div>
                                                        <Button color="primary" className="mr-1">
                                                            Search
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {vehicleList.length} entries</div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <Table className="table table-centered table-nowrap">
                                            <thead className="thead-light">

                                                <th>Vehicle No.</th>
                                                <th>Type</th>
                                                <th>Chassis Number</th>
                                                <th>Reg.Date</th>
                                                <th>UserId</th>
                                                <th></th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    currentPosts.map((vehicleList, key) =>
                                                        <tr key={"_order_" + key}>

                                                            <td>{vehicleList.vehicleNumber}</td>
                                                            <td>{vehicleList.vehicleType}</td>
                                                            <td>{vehicleList.chassisNumber}</td>
                                                            <td>
                                                                {new Date(vehicleList.createdAt).toLocaleString()}
                                                            </td>
                                                            <td>
                                                                {vehicleList.mtUserId}
                                                            </td>
                                                            <td>
                                                                <Badge className={vehicleList.status === "Unverified" ? "font-size-12 badge-soft-danger" : "font-size-12 badge-soft-success"} color={vehicleList.status === "Unverified" ? "denger" : "success"} pill>{vehicleList.status}</Badge>
                                                            </td>
                                                            <td>
                                                                <Link className="mr-1 text-success" onClick={() => viewVehicleDetails(vehicleList)}>
                                                                    <i className="mdi mdi-eye font-size-18 mr-1" id="edittooltip1"></i>
                                                                </Link>
                                                                <Link className="mr-1 text-info"
                                                                    to={`/EditVehicleDetails?vehicleId=${vehicleList.id}&mtUseridValue=${vehicleList.mtUserId}`}>
                                                                    <i className="mdi mdi-account-edit font-size-18 mr-1" id="edittooltip1"></i>
                                                                </Link>

                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination
                                                postsPerPage={postsPerPage}
                                                totalPosts={vehicleList.length}
                                                paginate={paginate} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
            {modelValue &&
                <MtModelBox sizeValue="xl" titleLabel="Vehicle Detials" modelValue={modelValue} modelSetValue={setmodelValue}>
                    <MtVehicleDetails jsonData={vehicleDetails}></MtVehicleDetails>
                </MtModelBox>
            }
        </React.Fragment>
    );
}

export default VehicleList;