import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Images
import profile1 from "../../assets/images/profile-img.png"
import avatar1 from "../../assets/images/users/avatar-1.jpg";
class ContactsProfile extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Deshboard" breadcrumbItem="Profile" />
                        <Row>
                            <Col xl="12">
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col xs="9">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">User Profile</h5>
                                                </div>
                                            </Col>
                                            <Col xs="3" className="align-self-end">
                                                <img src={profile1} alt="" className="img-fluid" style={{maxWidth:'60%',float:'right'}} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col sm="4">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <img src={avatar1} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                                <h5 className="font-size-15 text-truncate">UserName<i className="fa fa-check-circle m-2 text-success"></i></h5>
                                                <p className="text-muted mb-0 text-truncate">(Packers And Movers/Transporter)</p>
                                            </Col>

                                            <Col sm={8}>
                                                <div className="pt-4">
                                                    <div className="mt-4">
                                                        <Link to="/updateprofile" className="btn btn-primary waves-effect waves-light btn-sm">Add Business Listing<i className="mdi mdi-arrow-right ml-1"></i></Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">User Detail</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Company Name:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Contact Number:</th>
                                                        <td>Value</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">E-Mail:</th>
                                                        <td><a href="mailto:xyz@gmail.com">xyz@gmail.com</a></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Website:</th>
                                                        <td><a href="www.example.com">www.example.com</a></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Location:</th>
                                                        <td>Address,city,State,Pin</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactsProfile;