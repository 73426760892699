import React, {useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {  Row, Col } from "reactstrap";
import MtModelBox from "./MtModelBox";

const MtDownloadLayout=(props)=> {
    const [modelValue,setmodelValue]=useState(false);
    const [imgUrl,setImgUrl]=useState(null);
    const showImageHandler=(url)=>{
        setImgUrl(url);
        setmodelValue(true);
    }
            return (
            <Row style={{backgroundColor:"#e8f1ff",margin:2,padding: 3,borderRadius:10,}} className={props.imageUrl?"":"invisible"}>
            <Col lg={2}>
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                  <i className="bx bxs-image"></i>
                </span>
              </div>
            </Col>
            <Col lg={7}>
              <h5 className="font-size-14 mb-1 mt-2"><Link to="#" className="text-dark">{props.imageLabel}</Link></h5>
              <small>Type :{props.imageType}</small>
            </Col>
            <Col lg={1}>
              <div className="text-center">
                <a href={props.imageUrl} download rel="noopener noreferrer" target="_blank" className="text-dark"><i className="bx bx-download h3 m-0 mt-2"></i></a>
              </div>
              </Col>
            <Col lg={1}>
              <div className="text-center">
                <a onClick={()=>showImageHandler(props.imageUrl)}><i className="bx bx-show-alt text-success h3 m-0 mt-2"></i></a>
              </div>
            </Col>
            
{modelValue &&
    <MtModelBox sizeValue="lg" titleLabel="Image" modelValue={modelValue} modelSetValue={setmodelValue}>
         <img src={imgUrl} style={{width:"100%",height:300}} alt="Document" />
     </MtModelBox>
 }
          </Row>
        );
}

export default MtDownloadLayout;
