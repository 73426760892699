import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardFooter, Table, TabContent, TabPane, FormGroup, NavItem, NavLink, Button, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import VehicleType from "../../Components/VehicleType";
import UploadFile from '../../Components/uploadFile';
import classnames from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import MtSweetAlert from "../../Components/MtSweetAlert";
import MtDownloadLayout from "../../Components/MtDownloadLayout";
import { UpdateVehicleDetails, findOneVehicle } from "../../../services/api/api-service"
const EditVehicle = (props) => {
  let history = useHistory();
  const mtUseridValue = props.userId;
  const [vehicleIdValue] = useState(props.vehicleDetailsId);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [vehicleDocument, setVehicleDocument] = useState([]);
  const [vehicleTypeValue, setVehicleTypeValue] = useState(null);
  const [activeTabProgress, setActiveTabProgress] = useState(1);
  const [progressValue, setProgressValue] = useState(25);
  const [conformMsg, setConformMsg] = useState(false);
  const [success_msg, setSuccess_msg] = useState(false);
  const [statusValue, setStatusValue] = useState(false);
  //Image document
  const [penCardImage, setPenCardImage] = useState(null);
  const [rcImage, setRcImage] = useState(null);
  const [nationPermitImage, setNationPermitImage] = useState(null);
  const [insuranceImage, setInsuranceImage] = useState(null);
  const [fitnessImage, setFitnessImage] = useState(null);
  const [drivingLicenceImage, setDrivingLicence] = useState(null);
  const [vehicleDataList, setVehicleDataList] = useState([]);
  const goBack = () => {
    history.goBack()
    setConformMsg(false);
    setSuccess_msg(false);
  }
  const toggleTabProgress = (tab, values) => {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTabProgress(tab)
        if (tab === 1) {
          setProgressValue(35)
          console.log(values);
        }
        if (tab === 2) { setProgressValue(65) }
        if (tab === 3) { setProgressValue(100) }
      }
    }
  }
  const hendlerDocumentOnClick = (event, values) => {
    toggleTabProgress(Number(activeTabProgress) + 1)
    console.log(values);
    setVehicleDocument(values)
  }
  const hendlerOnClick = (event, values) => {
    toggleTabProgress(Number(activeTabProgress) + 1)
    setVehicleDetails(values);
    console.log(values);
  }
  const submitHandler = () => {
    const uploadData = [];
    uploadData.push({
      ...vehicleDetails,
      ...vehicleDocument,
      penCard: penCardImage,
      rcCard: rcImage,
      nationalPermit: nationPermitImage,
      insurance: insuranceImage,
      fitness: fitnessImage,
      drivingLicence: drivingLicenceImage,
      mtUserId: mtUseridValue,
      status: "Verified"
    })
    console.log(uploadData);
    UpdateVehicleDetails(vehicleIdValue,uploadData).then((result) => {
      setSuccess_msg(true);
      setConformMsg(false)
    }).catch((err) => {
    });
  }
  useEffect(() => {
    findOneVehicle(vehicleIdValue).then(data => {
      setVehicleDataList(data[0]);
      setVehicleTypeValue(vehicleDataList.vehicleType);
      //set Image already uploaded
      setPenCardImage(vehicleDataList.penCard);
      setRcImage(vehicleDataList.rcCard);
      setNationPermitImage(vehicleDataList.nationalPermit);
      setInsuranceImage(vehicleDataList.insurance);
      setDrivingLicence(vehicleDataList.drivingLicence);
      setFitnessImage(vehicleDataList.fitness)
      setStatusValue(true)
    })
  },[statusValue])
  return (
    <React.Fragment>
      <div id="EditVehicle" >
        <div id="FormView">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">{props.title}</h4>
              <div id="progrss-wizard" className="twitter-bs-wizard">
                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                  <NavItem>
                    <NavLink className={classnames(activeTabProgress === 1)} onClick={() => toggleTabProgress(1)} >
                      <span className="step-number mr-2">01</span> Vehicle Details</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames(activeTabProgress === 2)} onClick={() => toggleTabProgress(2)} >
                      <span className="step-number mr-2">02</span>
                      <span>Document Details</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames(activeTabProgress === 3)} onClick={() => toggleTabProgress(3)} >
                      <span className="step-number mr-2">03</span>
                      Preview Details
                    </NavLink>
                  </NavItem>
                </ul>
                <div id="bar" className="mt-4">
                  <Progress color="success" striped animated value={progressValue} />
                  <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                </div>
                <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
                  <TabPane tabId={1}>
                    <AvForm onValidSubmit={hendlerOnClick}>
                      <Row>
                        <Col md="4">
                          <AvField
                            name="vehicleNumber"
                            label="Vehicle Number"
                            placeholder="Enter vehicle number"
                            type="text"
                            value={_.get(vehicleDataList, "vehicleNumber") ? vehicleDataList.vehicleNumber : ""}
                            errorMessage="Enter vehicle number"
                            helpMessage="Exp:RJ01GS1212"
                            validate={{required:{ value: true}}}
                          />
                        </Col>
                        <Col md="4">
                          <VehicleType getVehicleType={setVehicleTypeValue} valueSelect={vehicleTypeValue} />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="modelno"
                            label="Model No"
                            value={_.get(vehicleDataList, "modelno") ? vehicleDataList.modelno : ""}
                            placeholder="Enter model number"
                            type="text"
                            errorMessage="Enter model number"
                          />
                        </Col>

                        <Col md="4">
                          <AvField
                            name="chassisNumber"
                            label="Chassis Number"
                            value={_.get(vehicleDataList, "chassisNumber") ? vehicleDataList.chassisNumber : ""}
                            placeholder="Enter chassis number"
                            type="text"
                            errorMessage="Enter chassis number"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="engineNo"
                            label="Engine No"
                            placeholder="Enter engine number"
                            value={_.get(vehicleDataList, "engineNo") ? vehicleDataList.engineNo : ""}
                            type="text"
                            errorMessage="Enter engine number"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="loadCapacity"
                            label="Load Capacity"
                            value={_.get(vehicleDataList, "loadCapacity") ? vehicleDataList.loadCapacity : ""}
                            placeholder="Enter load capacity"
                            type="text"
                            errorMessage="Enter load capacity"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="ownerName"
                            label="Owner Name"
                            placeholder="Enter owner name"
                            value={_.get(vehicleDataList, "ownerName") ? vehicleDataList.ownerName : ""}
                            helpMessage="Owner name must be have document"
                            type="text"
                            errorMessage="Enter owner name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="ownerContact"
                            label="Owner contact number"
                            maxLength="10"
                            placeholder="Owner contact number"
                            value={_.get(vehicleDataList, "ownerContact") ? vehicleDataList.ownerContact : ""}
                            type="number"
                            errorMessage="Owner contact number"
                            validate={{ required: { value: true } }}
                          /></Col>
                        <Col md="12">
                          <Button type="submit" className={activeTabProgress === 3 ? "btn btn-info mr-2 d-none float-right" : "btn btn-info mr-2 float-right"}>Next</Button>
                        </Col>
                      </Row>

                    </AvForm>

                  </TabPane>
                  <TabPane tabId={2}>
                    <div>
                      <AvForm onValidSubmit={hendlerDocumentOnClick}>
                        <Row>
                          <Col md="4">
                            <AvField
                              name="panNumber"
                              value={_.get(vehicleDataList, "panNumber") ? vehicleDataList.panNumber : ""}
                              label="PAN Number"
                              placeholder="Enter PAN Number"
                              type="text"
                              errorMessage="Enter Enter PAN Number"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="rcNumber"
                              label="RC Number"
                              value={_.get(vehicleDataList, "rcNumber") ? vehicleDataList.rcNumber : ""}
                              placeholder="Enter RC Number"
                              type="text"
                              errorMessage="Enter Enter RC Number"
                              validate={{ required: { value: true } }}
                            />
                          </Col>

                          <Col md="4">
                            <AvField
                              name="rcDate"
                              label="Vehicle Registration Date"
                              value={_.get(vehicleDataList, "rcDate") ? vehicleDataList.rcDate : ""}
                              placeholder="Enter Vehicle Registration Date"
                              type="date"
                              errorMessage="Enter Vehicle Registration Date"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="nationPermitNumber"
                              label="Nation Permit Number"
                              value={_.get(vehicleDataList, "nationPermitNumber") ? vehicleDataList.nationPermitNumber : ""}
                              placeholder="Enter Nation Permit Number"
                              type="text"
                              errorMessage="Enter Nation Permit Number"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="nationPermitRegistrationDate"
                              label="Nation Permit Date"
                              value={_.get(vehicleDataList, "nationPermitRegistrationDate") ? vehicleDataList.nationPermitRegistrationDate.slice(0, 10) : ""}
                              placeholder="Enter Nation Permit Date"
                              type="date"
                              errorMessage="Enter Nation Permit Date"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="nationPermitExpiryDate"
                              label="Nation Permit Expiry Date"
                              value={_.get(vehicleDataList, "nationPermitExpiryDate") ? vehicleDataList.nationPermitExpiryDate.slice(0, 10) : ""}
                              placeholder="Enter Nation Permit Expiry Date"
                              type="date"
                              errorMessage="Enter Nation Permit Expiry Date"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="insuranceNumber"
                              label="Insurance Number"
                              value={_.get(vehicleDataList, "insuranceNumber") ? vehicleDataList.insuranceNumber : ""}
                              placeholder="Enter Insurance Number"
                              type="text"
                              errorMessage="Enter Insurance Number"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="insuranceStarteDate"
                              label="Insurance Start Date"
                              value={_.get(vehicleDataList, "insuranceStarteDate") ? vehicleDataList.insuranceStarteDate.slice(0, 10) : ""}
                              placeholder="Enter Insurance Start Date"
                              type="date"
                              errorMessage="Enter Insurance Start Date"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="insuranceExpiryDate"
                              label="Insurance Expiry Date"
                              value={_.get(vehicleDataList, "insuranceExpiryDate") ? vehicleDataList.insuranceExpiryDate.slice(0, 10) : ""}
                              placeholder="Enter Insurance Start Date"
                              type="date"
                              errorMessage="Enter Insurance Expiry Date"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="fitnessNumber"
                              label="Fitness Number"
                              value={_.get(vehicleDataList, "fitnessNumber") ? vehicleDataList.fitnessNumber : ""}
                              placeholder="Enter Fitness Number"
                              type="text"
                              errorMessage="Enter Fitness Number"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="fitnessStarteDate"
                              label="Fitness Start Date"
                              value={_.get(vehicleDataList, "fitnessStarteDate") ? vehicleDataList.fitnessStarteDate.slice(0, 10) : ""}
                              type="date"
                              errorMessage="Enter Fitness Start Date" />
                          </Col>
                          <Col md="4">
                            <AvField
                              name="fitnessExpiryDate"
                              label="Fitness Expiry Date"
                              value={_.get(vehicleDataList, "fitnessExpiryDate") ? vehicleDataList.fitnessExpiryDate.slice(0, 10) : ""}
                              type="date"
                              errorMessage="Enter Fitness Expiry Date" />
                          </Col>
                        </Row>

                        <h4>Current document uploaded</h4>
                        <Row>
        <Col lg={4}>
          <MtDownloadLayout imageLabel="Pen Card" imageType="Vehicle Document" imageUrl={penCardImage} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="RC Card" imageType="Vehicle Document" imageUrl={rcImage} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="National Permit" imageType="Vehicle Document" imageUrl={nationPermitImage} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Insurance" imageType="Vehicle Document" imageUrl={insuranceImage} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Fitness" imageType="Vehicle Document" imageUrl={fitnessImage} />
         </Col>
         <Col lg={4}>
          <MtDownloadLayout imageLabel="Driving Licence" imageType="Vehicle Document" imageUrl={drivingLicenceImage} />
         </Col>
      </Row>
                       
                        <h4>Upload document</h4>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="Pan Card (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setPenCardImage}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="RC (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setRcImage}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="National Permit (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setNationPermitImage}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="Insurance (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setInsuranceImage}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="Driving Licence (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setDrivingLicence}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <UploadFile labelValue="Fitness Image (Upload File Format Must be exp: .JPG, .JPEG, .PNG)*"
                                bucketName="moverstrip-prod"
                                fileValue={setFitnessImage}
                                UploadPath="mt-user-document/" currentImage={null} />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <button className={activeTabProgress === 1 ? "btn btn-info d-none" : "btn btn-info"} onClick={() => toggleTabProgress(Number(activeTabProgress) - 1)}>Previous</button>
                            <Button type="submit" className={activeTabProgress === 3 ? "btn btn-info mr-2 d-none float-right" : "btn btn-info mr-2 float-right"}>Next</Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </TabPane>
                  <TabPane tabId={3}>
                    <div>
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 text-success">Vehicle Details</CardTitle>

                          <div className="table-responsive">
                            {vehicleDetails &&
                              <Table className="table table-sm m-0 table">
                                <tbody>
                                  <tr>
                                    <th scope="row">Vehicle Number:</th>
                                    <td>{vehicleDetails.vehicleNumber}</td>
                                    <th scope="row">Vehicle Type:</th>
                                    <td>{vehicleDetails.vehicleType}</td>
                                    <th scope="row">Model No:</th>
                                    <td>{vehicleDetails.modelno}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Chassis Number:</th>
                                    <td>{vehicleDetails.chassisNumber}</td>
                                    <th scope="row">Engine No:</th>
                                    <td>{vehicleDetails.engineNo}</td>
                                    <th scope="row">Load Capacity:</th>
                                    <td>{vehicleDetails.loadCapacity}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Owner Name:</th>
                                    <td>{vehicleDetails.ownerName}</td>
                                    <th scope="row">Owner Contact Number:</th>
                                    <td colSpan="3">{vehicleDetails.ownerContact}</td>
                                  </tr>

                                </tbody>
                              </Table>
                            }
                          </div>
                          <CardTitle className="mb-4 mt-4 text-success">Document Details</CardTitle>

                          <div className="table-responsive">
                            {vehicleDocument &&
                              <Table className="table table-sm m-0 table">
                                <tbody>
                                  <tr>
                                    <th scope="row">PAN Number:</th>
                                    <td>{vehicleDocument.panNumber}</td>
                                    <th scope="row">RC Number:</th>
                                    <td>{vehicleDocument.rcNumber}</td>
                                    <th scope="row">Vehicle Registration Date:</th>
                                    <td>{vehicleDocument.rcDate}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Nation Permit Number:</th>
                                    <td>{vehicleDocument.nationPermitNumber}</td>
                                    <th scope="row">Nation Permit Date:</th>
                                    <td>{vehicleDocument.nationPermitRegistrationDate}</td>
                                    <th scope="row">Nation Permit Expiry Date:</th>
                                    <td>{vehicleDocument.nationPermitExpiryDate}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Insurance Number:</th>
                                    <td>{vehicleDocument.insuranceNumber}</td>
                                    <th scope="row">Insurance Start Date:</th>
                                    <td>{vehicleDocument.insuranceNumber}</td>
                                    <th scope="row">Insurance Expiry Date:</th>
                                    <td>{vehicleDocument.insuranceNumber}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Fitness Number:</th>
                                    <td>{vehicleDocument.fitnessNumber}</td>
                                    <th scope="row">Fitness Start Date:</th>
                                    <td>{vehicleDocument.fitnessStarteDate}</td>
                                    <th scope="row">Fitness Expiry Date:</th>
                                    <td>{vehicleDocument.fitnessExpiryDate}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            }
                          </div>
                        </CardBody>
                        <CardFooter>
                          <button className={activeTabProgress === 1 ? "btn btn-info d-none" : "btn btn-info"} onClick={() => toggleTabProgress(Number(activeTabProgress) - 1)}>Previous</button>
                          <button className={activeTabProgress === 3 ? props.Type === "Edit" ? "btn btn-success mr-2 float-right visible" : "invisible" : "invisible"} onClick={() => setConformMsg(true)} >Save</button>
                        </CardFooter>
                      </Card>
                    </div>
                  </TabPane>
                </TabContent>

              </div>
            </CardBody>
          </Card>
        </div>
        {conformMsg ? (
          <MtSweetAlert
            alertTitle="Add Business Profile"
            alertConfirmBtnText="Submit"
            alertHandler={submitHandler}>
            Do You Submit Data
          </MtSweetAlert>
        ) : null}
        {success_msg ? (
          <MtSweetAlert
            alertTitle="Business Profile"
            alertConfirmBtnText="Go Back"
            alertHandler={goBack} >
            Business Profile Add Successfully
          </MtSweetAlert>
        ) : null}
      </div>

    </React.Fragment>
  );
}

export default EditVehicle;