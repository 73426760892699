import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withNamespaces } from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }
    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }
    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };
    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="bx bx-home-circle"></i>
                                <span>{this.props.t('Dashboard')}</span>
                            </Link>
                        </li>
                        <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="bx bxs-bus-school"></i>
                            <span>{this.props.t('Vehicle') }</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                             <li><Link to="GpsUser">{this.props.t('Gps User') }</Link></li>
                            {/* <li><Link to="vehicleRegister">{this.props.t('Vehicle Register') }</Link></li> */}
                            <li><Link to="vehicleList">{this.props.t('Vehicle List') }</Link></li>
                            <li><Link to="GpsDeviceDataList">{this.props.t('GPS Device Data List') }</Link></li>
                            {/* <li><Link to="searchVehicleDetails">{this.props.t('Find Vehicle Details') }</Link></li> */}
                            {/* <li><Link to="vehicleTypeList">{this.props.t('Vehicle Type List') }</Link></li> */}
                            {/* <li><Link to="rateCard">{this.props.t('Rate Card') }</Link></li> */}
                        </ul>
                    </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-list-ol"></i>
                                <span>{this.props.t('Business Listing')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="AddBusinessProfile">{this.props.t('Add Business Profile')}</Link></li>
                                <li><Link to="transporterList">{this.props.t('Transporter List')}</Link></li>
                                <li><Link to="packersAndMovers">{this.props.t('Packers & Movers')}</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-user"></i>
                                <span>{this.props.t('Users List')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="AddUser">{this.props.t('Add User')}</Link></li>
                                <li><Link to="MtgpsUserList">{this.props.t('MTGPS Users')}</Link></li>
                                <li><Link to="MtUsersList">{this.props.t('Moverstrip Users')}</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className='bx bxs-chart' ></i>
                                <span>{this.props.t('Report')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="subscriberUser">{this.props.t('Subscriber User')}</Link></li>
                                <li><Link to="unsubcriberUser">{this.props.t('Unsubscriber User')}</Link></li>
                                <li><Link to="todayUser">{this.props.t('Today User')}</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className='bx bxs-truck' ></i>
                                <span>{this.props.t('Vehicle Load')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                {/* <li><Link to="postload">{this.props.t('Post Load')}</Link></li> */}
                                {/* <li><Link to="memberLoadPost">{this.props.t('Member Post Load') }</Link></li> */}
                                <li><Link to="postloadlList">{this.props.t('Vehicle Load Available')}</Link></li>
                                <li><Link to="NeedLoadList">{this.props.t('Need Load List')}</Link></li>
                            </ul>
                        </li>
                         
                        <li>
                        <Link to="/#" className="has-arrow waves-effect">
                        <i className='bx bx-card' ></i>
                            <span>{this.props.t('Rate Card') }</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="RateCard">{this.props.t('Add rate card') }</Link></li>
                         
                        </ul>
                    </li>
                        {/* <li>
                         <Link to="/pricing" className="waves-effect">
                         <i className='bx bx-credit-card'></i>
                    <span>{this.props.t('Subscription') }</span>
                        </Link>
                     </li> */}
                        {/* <li>
                         <Link to="/transaction" className="waves-effect">
                         <i className='bx bx-transfer'></i>
                    <span>{this.props.t('Transaction') }</span>
                        </Link>
                     </li>*/}
                        {/* <li> 
                         <Link to="/faq" className="waves-effect">
                         <i className='bx bx-question-mark'></i>
                    <span>{this.props.t('FAQ') }</span>
                        </Link>
                     </li> */}
                    <li> 
                         <Link to="/help" className="waves-effect">
                         <i className='bx bxs-help-circle'></i>
                    <span>{this.props.t('Help') }</span>
                        </Link>
                     </li>
                     <li> 
                         <Link to="/ContactUs" className="waves-effect">
                         <i className='bx bx bxs-contact'></i>
                    <span>{this.props.t('Contact Us') }</span>
                        </Link>
                     </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
