import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup, Table, Badge } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import _ from 'lodash'
import { getGpsDeviceData } from "../../services/api/api-service"
import Pagination from '../Components/pagination';
import MapView from "../Components/MapView";
import MtModelBox from '../Components/MtModelBox';
import { Link } from "react-router-dom";
const GpsDeviceDataList = (props) => {
    //model box
    const [modelValue, setmodelValue] = useState(false);
    const [modelMapValue, setmodelMapValue] = useState(false);
    const [deviceData,setDeviceData]=useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    // Change page
    const [postsPerPage] = useState(50);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [userListData, setUserListData] = useState([]);
    const [liveLocation,setLiveLocation]=useState([]);
    useEffect(() => {
        getGpsDeviceData().then(data => {
            if (!_.isEmpty(data)) {
                setUserListData(data)
            }
        })

    }, [])
    const handleValidSubmit = (event, values) => {
        ///alert(values.searchName);
        getGpsDeviceData(values.searchName).then(data => {
            if (!_.isEmpty(data)) {
                setUserListData(data)
            }
        })
    }
    //show details handler
    const showDetailsHandler = (data) => {
        //console.log(data);
        setDeviceData(data);
        setmodelValue(true);
    }
    const showMapHandler=(data)=>{
        //console.log(data);
        setLiveLocation(data);
        setmodelMapValue(true);
    }
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = userListData.slice(indexOfFirstPost, indexOfLastPost);
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Vehicle" breadcrumbItem="Gps Device Data list" />
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="5">
                                                    <AvField name="searchName" label="Search User" required /></Col>
                                                <Col md="2">
                                                    <FormGroup className="mb-0 mt-4 ">
                                                        <Button color="primary" className="mr-1">
                                                            Search
                                                            </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <Row>
                            <Col lg="12">
                                <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {userListData.length} entries</div>
                            </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Vehicle Number</th>
                                                    <th scope="col">Device Id</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPosts.map(item => (
                                                    <tr key={item.id} >
                                                        <td>
                                                            {item.vehicle_number}
                                                        </td>
                                                        <td>
                                                            {item.device_id}
                                                        </td>
                                                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                                                        <td><Badge className={`font-size-9 p-2 ${item.motion_status === "moving"?"badge-soft-success":item.motion_status === "stopped" ? "badge-soft-danger" : item.motion_status === "no_network" ? "badge-soft-warning" : "badge-soft-secondary"}`}>{item.motion_status}</Badge>
                                                        </td>
                                                        <td>
                                                            <Link type="button" className="mr-1 action-icon text-success" onClick={() => showDetailsHandler(item)}>
                                                                <i className="mdi mdi-eye font-size-18 mr-2" id="viewDetails"></i>
                                                            </Link>
                                                            <Link type="button" className="mr-1 action-icon text-info" onClick={() => showMapHandler(item)}>
                                                                <i className="mdi mdi-map-search font-size-18 mr-2" id="viewDetails"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )

                                                )
                                                }
                                            </tbody>

                                        </Table>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={userListData.length}
                                    paginate={paginate} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {modelValue &&
                    <MtModelBox sizeValue="lg" titleLabel="GPS Device Details" modelValue={modelValue} modelSetValue={setmodelValue}>
                        <div className="table-responsive">
                            {deviceData &&
                                <Table className="table table-sm m-0 table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Vehicle Number:</th>
                                            <td>{deviceData.vehicle_number}</td>
                                            <th scope="row">Device Id:</th>
                                            <td>{deviceData.device_id}</td>
                                            <th scope="row">Type:</th>
                                            <td>{deviceData.type}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Address:</th>
                                            <td colSpan="5">{deviceData.address}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">City:</th>
                                            <td colSpan="2">{deviceData.city}</td>
                                            <th scope="row">State:</th>
                                            <td colSpan="2">{deviceData.state}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Motion Status:</th>
                                            <td colSpan="2"><Badge className={`font-size-9 ${deviceData.motion_status === "running" ? "badge-soft-success" : deviceData.motion_status == "stopped" ? "badge-soft-danger" : deviceData.motion_status === "no_network" ? "badge-soft-warning" : "badge-soft-secondary"}`}>{deviceData.motion_status}</Badge></td>
                                            <th scope="row">Speed:</th>
                                            <td colSpan="2">{deviceData.speed}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Date:</th>
                                            <td colSpan="2">{new Date(deviceData.createdAt).toLocaleString()}</td>
                                            <th scope="row">Motion State:</th>
                                            <td colSpan="2"><Badge className={`font-size-9 ${deviceData.motion_state === "running" ? "badge-soft-success" : deviceData.motion_state === "stopped" ? "badge-soft-danger" : deviceData.motion_state === "no_network" ? "badge-soft-warning" : "badge-soft-secondary"}`}>{deviceData.motion_state}</Badge></td>
                                           
                                        </tr>

                                    </tbody>
                                </Table>
                            }
                        </div>

                    </MtModelBox>
                }
                {modelMapValue &&
                <MtModelBox sizeValue="lg" titleLabel="Live Map" modelValue={modelMapValue} modelSetValue={setmodelMapValue}>
                   <MapView mapLocaiton={liveLocation}  />
                </MtModelBox>
                }
            </React.Fragment>
        </>
    );
}
export default GpsDeviceDataList;