import React, { Component } from "react";
import {Collapse, Card, Row, Col, CardBody,CardHeader, Container,FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			col1: true,
			col2: false,
			col3: false,
			col5: true
		};
		this.t_col1 = this.t_col1.bind(this);
		this.t_col2 = this.t_col2.bind(this);
		this.t_col3 = this.t_col3.bind(this);
		this.t_col5 = this.t_col5.bind(this);

	}


	t_col1() {
		this.setState({ col1: !this.state.col1 });
	}
	t_col2() {
		this.setState({ col2: !this.state.col2 });
	}
	t_col3() {
		this.setState({ col3: !this.state.col3 });
	}
	t_col5() {
		this.setState({ col5: !this.state.col5 });
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
                        <Breadcrumbs title="Help" breadcrumbItem="Faq" />
						<Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <AvForm>
                    <Row>
                       <Col md="8">
                       <AvField
                        name="searchText"
                        label="How can we help you ?"
                        placeholder="Type Keyword to Find answers"
                        type="Search"
                      /></Col>
                       <Col md="2">
                       <FormGroup className="mb-0 mt-4 ">
                        <div>
                          <Button type="submit" color="primary" onClick={this.ShowVehicleDetails} className="mr-1">
                           Search
                        </Button>{" "}
                        </div>
                      </FormGroup>
                           </Col>
                        </Row>
                     
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
						<Row>
                        <Col lg={12}>
								<Card>
									<CardBody>
										<div id="accordion">
											<Card className="mb-1">
												<CardHeader className="p-3" id="headingOne">
													<h6 className="m-0 font-14">
														<span onClick={this.t_col1}
															style={{ cursor: "pointer" }} className="text-dark">
															Collapsible Group Item #1
                          								</span>
													</h6>
												</CardHeader>

												<Collapse isOpen={this.state.col1}>
													<Card>
														<CardBody>
															Anim pariatur cliche reprehenderit, enim eiusmod
															high life accusamus terry richardson ad squid. 3
															wolf moon officia aute, non cupidatat skateboard
															dolor brunch. Food truck quinoa nesciunt laborum
															eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
															a bird on it squid single-origin coffee nulla
															assumenda shoreditch et. Nihil anim keffiyeh
															helvetica, craft beer labore wes anderson cred
															nesciunt sapiente ea proident. Ad vegan excepteur
															butcher vice lomo. Leggings occaecat craft beer
															farm-to-table, raw denim aesthetic synth nesciunt
															you probably haven't heard of them accusamus labore
															sustainable VHS.
                          								</CardBody>
													</Card>
												</Collapse>
											</Card>
											<Card className="mb-1">
												<CardHeader className="p-3" id="headingTwo">
													<h6 className="m-0 font-14">
														<span onClick={this.t_col2}
															style={{ cursor: "pointer" }} className="text-dark">
															{" "}Collapsible Group Item #2{" "}
														</span>
													</h6>
												</CardHeader>
												<Collapse isOpen={this.state.col2}>
													<Card>
														<CardBody>
															Anim pariatur cliche reprehenderit, enim eiusmod
															high life accusamus terry richardson ad squid. 3
															wolf moon officia aute, non cupidatat skateboard
															dolor brunch. Food truck quinoa nesciunt laborum
															eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
															a bird on it squid single-origin coffee nulla
															assumenda shoreditch et. Nihil anim keffiyeh
															helvetica, craft beer labore wes anderson cred
															nesciunt sapiente ea proident. Ad vegan excepteur
															butcher vice lomo. Leggings occaecat craft beer
															farm-to-table, raw denim aesthetic synth nesciunt
															you probably haven't heard of them accusamus labore
															sustainable VHS.
                          								</CardBody>
													</Card>
												</Collapse>{" "}
											</Card>
											<Card className="mb-0">
												<CardHeader className="p-3" id="headingThree">
													<h6 className="m-0 font-14">
														<span onClick={this.t_col3}
															style={{ cursor: "pointer" }} className="text-dark">
															Collapsible Group Item #3
                          								</span>
													</h6>
												</CardHeader>
												<Collapse isOpen={this.state.col3}>
													<Card>
														<CardBody>
															Anim pariatur cliche reprehenderit, enim eiusmod
															high life accusamus terry richardson ad squid. 3
															wolf moon officia aute, non cupidatat skateboard
															dolor brunch. Food truck quinoa nesciunt laborum
															eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
															a bird on it squid single-origin coffee nulla
															assumenda shoreditch et. Nihil anim keffiyeh
															helvetica, craft beer labore wes anderson cred
															nesciunt sapiente ea proident. Ad vegan excepteur
															butcher vice lomo. Leggings occaecat craft beer
															farm-to-table, raw denim aesthetic synth nesciunt
															you probably haven't heard of them accusamus labore
															sustainable VHS.
                          								</CardBody>
													</Card>
												</Collapse>{" "}
											</Card>
										</div>
									</CardBody>
								</Card>
							</Col>

						</Row>

					</Container>
				</div>

			</React.Fragment>
		);
	}
}

export default Faq;
