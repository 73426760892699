import React, { useState } from 'react';
import { Map, Marker, GoogleApiWrapper} from "google-maps-react";
import { connect } from "react-redux";
import TruckImage from "../../assets/images/truck-map.png"
//Import Breadcrumb
const LoadingContainer = (props) => <div>Loading...</div>;
const MapView = (props) => {
  console.log(props.mapLocaiton);
  const [iconValue]=useState({
    iconUrl: TruckImage,
});
  // const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  // const [activeMarker, setActiveMarker] = useState({});
  const [cordinateValue]=useState(props.mapLocaiton.cordinate);
  return (
    <React.Fragment>
      <div>
      <h4>{props.mapLocaiton.vehicle_number}<span style={{marginLeft: 10,}} className={`badge badge-pill ${props.mapLocaiton.motion_status=="offline"?
      "badge-soft-secondary":props.mapLocaiton.motion_status=="stopped"?
      "badge-soft-danger":props.mapLocaiton.motion_status=="moving"?
      "badge-soft-success":"badge-soft-secondary"} font-size-11`}>{props.mapLocaiton.motion_status}</span></h4>
              <p><strong>Location:</strong>{props.mapLocaiton.address}</p>
      </div>
      <div
        id="gmaps-overlay"
        className="gmaps"
        style={{ position: "relative", height: "500px" }}
      >
        <Map
          google={props.google}
          zoom={20}
          initialCenter={cordinateValue}
          style={{ width: "100%", height: "100%" }}
        >
          <Marker
           icon={{url:iconValue.iconUrl}} 
           name={'Current location'}
           position={cordinateValue}
              >
               
              </Marker>
          {/* <InfoWindow  marker={activeMarker} 
            visible={showingInfoWindow}>
            <div>
              <h6>{props.mapLocaiton.vehicle_number}</h6>
              <p><strong>Location:</strong>{props.mapLocaiton.address}</p>
            </div>
          </InfoWindow> */}
        </Map>
      </div>
    </React.Fragment>
  );
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBxgrIOwPu6fdAgDefZ0jE8knqjHT5gme8",
    LoadingContainer: LoadingContainer,
    v: "3"
  })(MapView)
);