import React, { Component } from 'react';
import {Button} from "reactstrap";
class VehicleLiveList extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.projects.map((project, key) =>
                                                <tr key={"_project_" + key} >
                                                    <td>
                                                        {project.Vehicle}
                                                    </td>
                                                    <td>
                                                       {project.vehicletype}
                                                    </td>
                                                    <td>
                                                        {project.Location}
                                                    </td>
                                                    <td>
                                                        {project.destination}
                                                    </td>
                                                    <td>{project.contactName}</td>
                                                    <td><Button type="button" color="primary" className="btn-sm btn-info">
                                                     Find Contact
                            </Button></td>
                        </tr>
                    )
                }

            </React.Fragment>
        );
    }
}

export default VehicleLiveList;