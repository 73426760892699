import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { UncontrolledTooltip} from "reactstrap";
class MemberLoadCard extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.projects.map((project, key) =>
                                                <tr key={"_project_" + key} >
                                                 
                                                    <td>
                                                       {project.vehicletype}
                                                    </td>
                                                    <td>
                                                       {project.pickupLocation}
                                                    </td>
                                                    <td>
                                                       {project.destination}
                                                    </td>
                                                    <td>{project.date}</td>
                                                    <td>{project.goodsType}</td>
                                                    <td>
                                                    <Link to="editVehicleDetails" className="mr-3 text-success">
                                                                        <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            Edit
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                </td>
                                                </tr>
                    )
                }

            </React.Fragment>
        );
    }
}

export default MemberLoadCard;