import React, { Component } from 'react';
import { Container, Row, Col, Table,Card, CardBody,CardTitle, CardFooter} from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1, actionName: "Map", date: "12 Jun 2020",clicksConsumed: "10",
                },
            ],
            purchasehistory: [
                {
                    id: 1, planName: "Gold Plan", paymentDate: "12 Jun 2020",numberOfClicksAdded: "200",paymentMethod:"Card",balance:"1000"
                },
            ]
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Transaction" breadcrumbItem="Transaction List" />
                        <Row>
              <Col lg={6}>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="float-left">Transaction</CardTitle>
                
                  </CardBody>
                </Card></Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    
                                                    <th scope="col">Action name</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Clicks Consumed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            this.state.projects.map((projects, key) =>
                                            <tr key={"_project_" + key} >
                                                   
                                                    <td>
                                                        {projects.actionName}
                                                    </td>
                                                    <td>
                                                        {projects.date}
                                                    </td>
                                                    <td>
                                                        {projects.clicksConsumed}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                   
                            </Col>
                            <Col lg="12">
                    <Link to="#" className="btn btn-success float-right btn-sm">View All </Link>
                        </Col>                        
                         
                         </Col>   
              <Col lg={6}>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="float-left">Purchase History</CardTitle>
                  </CardBody>
                </Card></Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                   
                                                    <th scope="col">Plan Name</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Click Added</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            this.state.purchasehistory.map((projects, key) =>
                                            <tr key={"_project_" + key} >
                                                    
                                                    <td>
                                                        {projects.planName}
                                                    </td>
                                                    <td>
                                                        {projects.paymentDate}
                                                    </td>
                                                    <td>
                                                        {projects.numberOfClicksAdded}
                                                    </td>
                                                    <td>
                                                        {projects.paymentMethod}
                                                    </td>
                                                    <td>
                                                        {projects.balance}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="12">
                    <Link to="#" className="btn btn-success float-right btn-sm">View All </Link>
                        </Col>   
                            </Col>
                        </Row>
                       
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default Transaction;