export default {
    primaryColor: '#2948df',
    accentColor: '#ff6f00',
    primaryButtonBgColor:'#0288d1',
    secondaryButtonBgColor:'#e65100',
    bodyTextColor:'#444',
    modalHeaderBackgroundColor:'#303f9f',
    modalHeaderTextColor:"#FFF",
    topMenuHeaderColor:"#3f51b5",
    linkTextColor:'#556ee6',
    //apiUrl: "http://localhost:3000/api"
    //apiUrl: "https://staging.moverstrip.com/api"
    apiUrl: "https://api.moverstrip.com/api"
};