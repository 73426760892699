import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Button, Label, Input } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import _ from 'lodash'
const MtFormLayout = (props) => {
    return (
        <React.Fragment>
              <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.breadcrumbsTitle} breadcrumbItem={props.breadcrumbItem} />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{props.title}</h4>
                                    <p className="card-title-desc"> {props.subTitle}</p>
                                    {props.children}
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default MtFormLayout;