import React, { useState } from 'react';
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import { Label, Row, Col, Button, Card, FormGroup, CardBody, Table, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from 'lodash'
import Select from "react-select";
import { getUserBusinessList,findUser } from "../../services/api/api-service"
import globalData from '../../services/global-data'
const UserFilter = (props) => {
    const [verifyStatus, setVerifyStatus] = useState(globalData.userStatus)
    const [verifyValue] = useState([])
    const [userListData, setUserListData] = useState([]);
    const [selectValue, setSelectValue] = useState(null);
    const filterTypeUser=props.userFilterType;
    const handleChange = (event) => {
        setSelectValue(event)
        console.log(event.value);
        getUserBusinessList(filterTypeUser, event.value).then(data => {
            console.log(data);
            if (!_.isEmpty(data)) {
                props.userlists(data);
                console.log(data);
            }
        })
    }
    const handleValidSubmit = (event, values) => {
        //alert(values.searchName);
        findUser(filterTypeUser, values.searchName).then(data => {
            if (!_.isEmpty(data)) {
                props.userlists(data);
            }
        })
    }
    return (
        <React.Fragment>
            <AvForm onValidSubmit={handleValidSubmit}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="2">
                                        <Label for="example">Filter Status</Label>
                                        <Select
                                            defaultValue={selectValue}
                                            name="verifyValueStatus"
                                            onChange={handleChange}
                                            value={selectValue}
                                            options={verifyStatus}
                                        />

                                    </Col>
                                    <Col md="3">
                                        <AvField name="searchName" label="Search User" required /></Col>
                                       
                                    <Col md="2">
                                        <FormGroup className="mb-0 mt-4 ">
                                            <Button color="primary" className="mr-1">
                                                Search
                                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </AvForm>

        </React.Fragment>
    );
}

export default UserFilter;