import React, { Component } from 'react';
import MapView from "../Components/MapView";
import { Row, Col, Card, CardBody,FormGroup,CardTitle, Button} from "reactstrap";
import VehicleType from "../Components/VehicleType";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
const LoadingContainer = props => <div>Loading...</div>;
class LiveVehicle extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Live Vehicle" breadcrumbItem="Live Vehicle" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                   
                    <AvForm>
                    <Row>
                    <Col md="2">
                        <VehicleType />
                      </Col>
                       <Col md="4">
                       <AvField
                        name="searchText"
                        label="Location Search"
                        placeholder="Search..."
                        helpMessage="Exp:city, state, locaiton"
                        type="searchText"
                      /></Col>
                       <Col md="2">
                       <FormGroup className="mb-0 mt-4 ">
                        <div>
                          <Button type="submit" color="primary" className="mr-1">
                            Search
                        </Button>{" "}
                         
                        </div>
                      </FormGroup>
                           </Col>
                        </Row>
                     
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                   <MapView />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LiveVehicle;