import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Label, FormGroup, Tooltip, Table, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import UserFilter from "../Components/userFilter"
import globelData from '../../services/global-data'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import _ from 'lodash'
import ResetPassword from '../Components/resetPassword';
import { changeStatus, getUserBusinessProfile, getUserBusinessList } from "../../services/api/api-service"
import Pagination from '../Components/pagination';
import MtTooltip from '../Components/MtTooltip';
import { Link } from "react-router-dom";
const GpsUser = (props) => {
    const [conformMsg, setConformMsg] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [resposeValue, setResposeValue] = useState(null);
    // Change page
    const [postsPerPage] = useState(50);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [userListData, setUserListData] = useState([]);
    useEffect(() => {
        getUserBusinessList("MTGPS","A").then(data => {
            //console.log(data[0]);
            if (!_.isEmpty(data)) {
                setUserListData(data)
            }
        })

    }, [])

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = userListData.slice(indexOfFirstPost, indexOfLastPost);
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="User" breadcrumbItem="Mt GPS User List" />
                        <UserFilter userlists={setUserListData} userFilterType="MTGPS" />
                        <Row>
                            <Col lg="12">
                                <div class="dataTables_info" role="status" aria-live="polite">Showing {indexOfFirstPost} to {indexOfLastPost} of {userListData.length} entries</div>
                            </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Contact Number</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">User Type</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPosts.map(item => (
                                                    <tr key={item.id} >
                                                        <td>
                                                            {item.contactName}
                                                        </td>
                                                        <td>
                                                            {item.username}
                                                        </td>
                                                        <td>{new Date(item.codeCreatedAt).toLocaleString()}</td>
                                                        <td> {item.userType}  </td>
                                                        <td><Badge className={_.get(item, "status") ? item.status == "A" ? "font-size-9 badge-soft-success" : "font-size-9 badge-soft-danger" : "font-size-9 badge-soft-danger"} color={_.get(item, "status") ? item.status == "A" ? "success" : "danger" : "danger"} pill>{_.get(item, "status") ? item.status == "A" ? "Verify" : "Unverify" : "Unverify"}</Badge>
                                                        </td>
                                                        <td> 
                                                            <Link
                                                                color="primary"
                                                                to={`/vehicleRegister?mtUserId=${item.id}`}
                                                                className={`btn btn-primary waves-effect waves-light mr-2 btn-sm ${_.get(item, "status") ? item.status == "A" ? "" : "invisible" : "invisible"}`}
                                                            >
                                                                <i className="mdi mdi-truck font-size-16 align-middle mr-2" id="userActive" ></i>
                                                                Add Vehicle
                                                            </Link>
                                                         <Link
                                                                color="primary"
                                                                to={`/UserVehicleList?mtUserId=${item.id}`}
                                                                className="btn btn-info waves-effect waves-light btn-sm"
                                                            >
                                                                <i className="mdi mdi-truck-delivery font-size-16 align-middle mr-2" id="allvehicle" ></i>
                                                                All Vehicle
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )

                                                )
                                                }
                                            </tbody>

                                        </Table>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={userListData.length}
                                    paginate={paginate} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
}
export default GpsUser;