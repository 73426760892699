import React, { useEffect,useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, FormGroup, CardTitle, Button,Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Cards
import VehicleType from "../Components/VehicleType";
import Select from "react-select";
import _ from 'lodash';
import {getSystemList,createRateCard,findRateCardList} from "../../services/api/api-service";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Image
const RateCard = () => {
    const [cityStateValue, setCityStateValue] = useState(null);
    const [cityStateList, setCitylist] = useState([]);
    const [vehicleTypeValue, setVehicleTypeValue] = useState(null)
    const [dataList,setDataList]=useState([]);
    const [location,setLocation]=useState(null);
    const handleValidSubmit = (event, values, actions) => {
        const dataValue = [];
        dataValue.push({
            location:cityStateValue.label,
            charges:values.costValue,
            vehicleType:vehicleTypeValue
        });
        createRateCard(dataValue).then(result=>{
            // console.log("-------Rate Card-------");
            // console.log(result);
            // console.log("-------Rate Card-------");
            findRateCardList(_.get(cityStateValue,"label")?cityStateValue.label:null,vehicleTypeValue).then(data=>{
                setDataList(data);
                console.log(data);
            })
        })
    }
    useEffect(() => {
        getSystemList('state').then(data => {
            if (!_.isEmpty(data)) {
                setCitylist(data)
            }
        }).catch(e => {
            console.log("Error in city/State list:", e);
        })
    }, [])
    useEffect(()=>{
        setLocation(_.get(cityStateValue,"label")?cityStateValue.label:null);
        findRateCardList(_.get(cityStateValue,"label")?cityStateValue.label:null,vehicleTypeValue).then(data=>{
            setDataList(data);
            console.log(data);
        })  
    },[cityStateValue])
    useEffect(()=>{
        setDataList([]);
        findRateCardList(_.get(cityStateValue,"label")?cityStateValue.label:null,vehicleTypeValue).then(data=>{
            setDataList(data);
        })  
    },[vehicleTypeValue])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Rate Card" breadcrumbItem="Add rate card" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Add Rate Card</CardTitle>
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label htmlFor="validationCustom01">City/State</Label>
                                                    <Select
                                                        name="cityState"
                                                        value={cityStateValue}
                                                        onChange={setCityStateValue}
                                                        options={cityStateList}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <VehicleType getVehicleType={setVehicleTypeValue} />
                                            </Col>
                                            <Col md="3">
                                            <FormGroup>
                                                <AvField
                                                    name="costValue"
                                                    label="Cost Value"
                                                    placeholder="5"
                                                    helpMessage="Exp:5"
                                                    type="searchText"
                                                />
                                                </FormGroup> </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-0 mt-4 ">
                                                    <div>
                                                    <Button color="success" type="submit">Submit</Button>

                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                <div class="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                    </Col>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Vehicle Type</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Rate(Per KM)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                        dataList.map((order, key) =>
                                                            <tr key={"_order_" + key}>
                                                              
                                                                <td>{order.vehicleType}</td>
                                                                <td>{order.location}</td>
                                                                <td>
                                                                    {order.charges}
                                                                </td>
                                                                </tr>
                                                        )
    }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    <Row>



                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default RateCard;